import React, { useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import URLs from "../utils/urls";
// Components
import FixedHeader from "./FixedBars/FixedHeader";
import FixedLeftBar from "./FixedBars/FixedLeftBar";
import FixedRightBar from "./FixedBars/FixedRightBar";
import LeftBlock from "./LeftBlock";
import Region from "./Region";
import RightBlock from "./RightBlock";
import { DispatchContext, StateContext, StateProvider } from "./stateContext";

// Images
import BG from "../assets/images/board-bg.jpg";

// css
import "../assets/css/FixedBars.css";
import "../assets/css/Style3dModel.css";
import axios from "axios";

let scrollIntervalTime = undefined;

export default function SimService({ canEdit, teamNameValue, isAdmin, isSessionLong, languages }) {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const [socket, setSocket] = useState(undefined);
  const [counter, setCounter] = useState(undefined);
  const [teamName, setTeamName] = useState("");
  const [eventModal, setEventModal] = useState(false);
  const [activeEvent, setActiveEvent] = useState(undefined);

  const [checklist, setChecklist] = useState([])

  const [zoomValue, setZoom] = useState(100);

  const updateDrageMousePosition = (ev) => {
    //console.log(ev)
    if (ev.clientX >= window.innerWidth - 100) {
      if (!scrollIntervalTime) {
        scrollIntervalTime = setInterval(() => {
          scrollRightAction();
        }, 50);
      }
    } else if (ev.clientX <= 100) {
      if (!scrollIntervalTime) {
        scrollIntervalTime = setInterval(() => {
          scrollLeftAction();
        }, 50);
      }
    } else {
      if (scrollIntervalTime) {
        clearInterval(scrollIntervalTime);
        scrollIntervalTime = undefined;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("dragover", updateDrageMousePosition);
    return () => window.removeEventListener("dragover", updateDrageMousePosition);
  }, []);

  const scrollRightAction = () => {
    window.scrollBy({
      left: 50,
      behavior: "smooth",
    });

    if (
      document.body.scrollWidth - window.innerWidth ===
      window.pageXOffset - (window.innerWidth - document.body.offsetWidth)
    ) {
      if (scrollIntervalTime) {
        clearInterval(scrollIntervalTime);
        scrollIntervalTime = undefined;
      }
    }
  };

  const scrollLeftAction = () => {
    window.scrollBy({
      left: -50,
      behavior: "smooth",
    });

    if (window.pageXOffset === 0) {
      if (scrollIntervalTime) {
        clearInterval(scrollIntervalTime);
        scrollIntervalTime = undefined;
      }
    }
  };

  const fetchChecklist = async () => {
    const response = await axios.post(URLs.checklist, {
      sessionId: window.sessionId,
      languageId: localStorage.langId
    })

    console.log(response)
    if (response.data.success) {
      setChecklist(response.data.checklist)
    }
  }

  useEffect(() => {
    if (!window.socket) {
      setupSocket();
    }

    fetchChecklist()
  }, []);

  useEffect(() => {
    setTeamName(teamNameValue);
  }, [teamNameValue]);

  const setupSocket = () => {
    const socketObj = socketIOClient(URLs.socketEndpoint, {
      path: process.env.REACT_APP_SOCKET_PATH,
      upgrade: true,
      transports: ["polling"], //['websocket', 'polling']
    });
    window.socket = socketObj;

    setSocket(socketObj);
  };

  useEffect(() => {
    if (socket) {
      socket.on("connect", (data) => {
        console.log("Connected To WS");

        socket.emit("user-data", {
          user_id: window.playerId,
          team_id: window.teamId,
          session_id: window.sessionId,
          token: window.token,
        });
      });

      socket.on("disconnect", (data) => {
        console.log("Disconnected From WS");
      });

      socket.on("restore-session", (data) => {
        dispatch({
          type: "RestoreTeamTable",
          data: data.starterKitStateObj,
        });
        dispatch({ type: "RestoreBoard", data: data.state });
        dispatch({ type: "RestoreMsgs", data: data.messages });
        dispatch({ type: "changeYear", year: data.year });
        dispatch({ type: "files", files: data.files });
        dispatch({
          type: "RestoreForm",
          data: data.sessionForms,
        });
      });

      socket.on("restore-labor-market", (data) => {
        dispatch({ type: "RestoreLaborMarket", data: data });
      });

      socket.on("change-year", (data) => {
        dispatch({ type: "changeYear", year: data.year });
        dispatch({
          type: "changeFormYear",
          data: data,
        });
      });
      socket.on("change-form-year", (data) => {
        dispatch({
          type: "changeFormYear",
          data: data,
        });
      });
      socket.on("change-location-status", (data) => {
        dispatch({
          type: "locationStatus",
          ...data,
        });
      });

      socket.on("add-timer", (data) => {
        console.log(data);
        setCounter(data.data);
      });

      socket.on("remove-timer", (data) => {
        setCounter(undefined);
      });

      socket.on("update-labor-market", (data) => {
        dispatch({ type: "LaborMarket", data: data });
      });

      socket.on("update-team-table", (data) => {
        dispatch({ type: "UpdateTeamTable", data: data });
      });

      socket.on("update-region-customer-data", (data) => {
        dispatch({ type: "UpdateRegionCustomerData", data: data });
      });

      socket.on("update-board-value", (data) => {
        dispatch({ type: "UpdateBoardValue", data: data });
      });

      socket.on("decrease-starter-kit", (data) => {
        dispatch({ type: "DecreaseTeamTable", data: data });
      });

      socket.on("increase-starter-kit", (data) => {
        dispatch({ type: "IncreaseTeamTable", data: data });
      });
      socket.on("decrease-cash", (data) => {
        dispatch({ type: "DecreaseCash", data: data });
      });

      socket.on("increase-cash", (data) => {
        dispatch({ type: "IncreaseCash", data: data });
      });

      socket.on("add-container", (data) => {
        dispatch({ type: "addContainer", ...data });
      });
      socket.on("updateForm", (data) => {
        switch (data.type) {
          case "decisionSheet":
            dispatch({ type: "updateDecisionSheet", data: data });
            break;
          case "annualReport":
            dispatch({ type: "updateAnnualReport", data: data });
            break;
          case "strategyPaper":
            dispatch({ type: "updateStrategyPaper", data: data });
            break;
          default:
            break;
        }
      });
      socket.on("annualReport", (data) => {
        dispatch({ type: "changeAnnualReport", ...data });
      });
      socket.on("strategyPaper", (data) => {
        dispatch({ type: "changeStrategyPaper", ...data });
      });

      socket.on("remove-container", (data) => {
        dispatch({ type: "removeContainer", ...data });
      });

      socket.on("add-coin", (data) => {
        dispatch({ type: "addCoin", ...data });
      });

      socket.on("remove-coin", (data) => {
        dispatch({ type: "removeCoin", ...data });
      });

      socket.on("add-equity", (data) => {
        dispatch({ type: "addEquity", ...data });
      });

      socket.on("add-token", (data) => {
        dispatch({ type: "addToken", ...data });
      });

      socket.on("remove-token", (data) => {
        dispatch({ type: "deleteToken", ...data });
      });

      socket.on("add-label", (data) => {
        dispatch({ type: "addLabel", ...data });
      });

      socket.on("remove-label", (data) => {
        dispatch({ type: "deleteLabel", ...data });
      });

      socket.on("add-avatar", (data) => {
        dispatch({ type: "addAvatar", ...data });
      });

      socket.on("remove-avatar", (data) => {
        dispatch({ type: "deleteAvatar", ...data });
      });

      socket.on("add-pin", (data) => {
        dispatch({ type: "addPin", ...data });
      });

      socket.on("remove-pin", (data) => {
        dispatch({ type: "deletePin", ...data });
      });

      socket.on("add-time", (data) => {
        dispatch({ type: "addTime", ...data });
      });

      socket.on("subtract-time", (data) => {
        dispatch({ type: "subtractTime", ...data });
      });

      socket.on("container-dragging", (data) => {
        dispatch({ type: "containerDragging", ...data });
      });
      socket.on("coin-dragging", (data) => {
        dispatch({ type: "coinDragging", ...data });
      });

      socket.on("add-file", (data) => {
        if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
          console.log(data);

          dispatch({
            type: "addFiles",
            files: data,
          });
        }
      });

      socket.on("remove-file", (data) => {
        if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
          dispatch({
            type: "removeFiles",
            files: data,
          });
        }
      });

      socket.on("change-team", (data) => {
        setTeamName(data);
      });
      socket.on("session-status-changed", (data) => {
        window.location.reload();
      });

      socket.on("session-active-event", (data) => {
        console.log(data);
        if (data.status) {
          setActiveEvent(data.eventData);
          setEventModal(true);
        } else {
          setActiveEvent(undefined);
          setEventModal(false);
        }
      });

      socket.on("list-message", (data) => {
        dispatch({ type: "messagesList", message: { ...data } });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <StateProvider>
      <div className="board">
        <img src={BG} alt="" className="bg" />
        <FixedHeader
          canEdit={canEdit}
          counter={counter}
          teamName={teamName}
          session={state.Session}
          dispatch={dispatch}
          setZoom={setZoom}
          zoomValue={zoomValue}
          languages={ languages }
        />
        <FixedLeftBar canEdit={canEdit} state={state.TeamTable} />
        <FixedRightBar
          canEdit={canEdit}
          dispatch={dispatch}
          formStatus={state.Session["formStatus"]}
          decisionSheet={state.decisionSheet}
          annualReport={state.annualReport}
          strategyPaper={state.strategyPaper}
          session={state.Session}
          isSessionLong={isSessionLong}
          checkList={ checklist }
        />
        <div className={`center-container d-flex zoom-${zoomValue}`}>
          <LeftBlock
            state={state.Board.leftBlock}
            dispatch={dispatch}
            coinsCount={state.TeamTable.coins}
            canEdit={canEdit}
            pinsCount={state.TeamTable.pins}
          />
          <div>
            {state.Board.regions.map((region, y) => (
              <Region
                region={region}
                key={y}
                regionIndex={y}
                dispatch={dispatch}
                coinsCount={state.TeamTable.coins}
                pinsCount={state.TeamTable.pins}
                canEdit={canEdit}
              />
            ))}
          </div>
          <RightBlock
            state={state.Board.rightBlock}
            dispatch={dispatch}
            coinsCount={state.TeamTable.coins}
            canEdit={canEdit}
          />
        </div>
      </div>
    </StateProvider>
  );
}
