import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Collapse, Button, Card } from "reactstrap";

// Css
import "../assets/css/region.css";
import Employee from "./blocks/Employee";
import Service from "./blocks/Service";
import PinContainer from "./draggables/PinContainer";

import Translate from "../utils/Translate";

export default function Region({ canEdit, region, regionIndex, dispatch, coinsCount, pinsCount }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Button onClick={toggle} className="region-block px-3">
        <div className="d-flex align-items-center w-100">
          <span>
            <Translate alias="Board" word="Region" />
            {[...Array(regionIndex + 1)].map((x, y) => " |")}
          </span>
          {region.pins.map((pin, y) => (
            <PinContainer
              key={y}
              index={y}
              checked={pin.checked}
              dispatch={dispatch}
              regionIndex={regionIndex}
              canEdit={canEdit}
              pinsCount={pinsCount}
            />
          ))}
        </div>
        <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
      </Button>
      <Collapse isOpen={isOpen}>
        <Card style={{ padding: "15px", borderTop: "1px solid black", borderRadius: 0 }}>
          <div>
            <span><Translate alias="Board" word="Services" /></span>
            <div className="d-flex mt-2">
              {region.services.map((service, index) => (
                <Service
                  coinsCount={coinsCount}
                  service={service}
                  regionIndex={regionIndex}
                  serviceIndex={index}
                  key={index}
                  dispatch={dispatch}
                  canEdit={canEdit}
                />
              ))}
            </div>
          </div>
          <div>
            <span><Translate alias="Board" word="Employees" /></span>
            <div className="employee-container">
              {region.employees.map((employee, index) => (
                <Employee
                  employee={employee}
                  regionIndex={regionIndex}
                  id={index}
                  key={index}
                  serviceIndex={index}
                  dispatch={dispatch}
                  canEdit={canEdit}
                />
              ))}
            </div>
          </div>
        </Card>
      </Collapse>
    </div>
  );
}
