import React from "react";
import { Table, FormControl } from "react-bootstrap";
import InputNumber from "rc-input-number";
import RealtimeConnection from "../../../utils/RealtimeConnection";

import Translate from "../../../utils/Translate";

class IncomeStatement extends React.Component {
  handleChange(field, value) {
    if (this.props.canEdit) {
      RealtimeConnection.changeAnnualReport({
        year: this.props.year,
        groupName: "incomeStatement",
        field,
        value,
      });
    }
  }

  render() {
    let grossProfit = 0;
    grossProfit += !isNaN(parseFloat(this.props.incomeStatement.revenues))
      ? parseFloat(this.props.incomeStatement.revenues)
      : 0;
    grossProfit -= !isNaN(parseFloat(this.props.incomeStatement.costOfSales))
      ? parseFloat(this.props.incomeStatement.costOfSales)
      : 0;

    let total = 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.personnelCostManager))
      ? parseFloat(this.props.incomeStatement.personnelCostManager)
      : 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.hq))
      ? parseFloat(this.props.incomeStatement.hq)
      : 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.marketing))
      ? parseFloat(this.props.incomeStatement.marketing)
      : 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.indirectSalesCost))
      ? parseFloat(this.props.incomeStatement.indirectSalesCost)
      : 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.severanceCosts))
      ? parseFloat(this.props.incomeStatement.severanceCosts)
      : 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.hrDevelopmentCosts))
      ? parseFloat(this.props.incomeStatement.hrDevelopmentCosts)
      : 0;
    total += !isNaN(parseFloat(this.props.incomeStatement.lease))
      ? parseFloat(this.props.incomeStatement.lease)
      : 0;

    let EBITDA = grossProfit - total;

    let ebit = EBITDA;

    ebit -= !isNaN(parseFloat(this.props.incomeStatement.property))
      ? parseFloat(this.props.incomeStatement.property)
      : 0;
    ebit -= !isNaN(parseFloat(this.props.incomeStatement.equipment))
      ? parseFloat(this.props.incomeStatement.equipment)
      : 0;
    ebit -= !isNaN(parseFloat(this.props.incomeStatement.other))
      ? parseFloat(this.props.incomeStatement.other)
      : 0;

    let earningBeforeTax = ebit;

    earningBeforeTax -= !isNaN(parseFloat(this.props.incomeStatement.interest))
      ? parseFloat(this.props.incomeStatement.interest)
      : 0;

    let annualResult = earningBeforeTax;
    annualResult -= !isNaN(parseFloat(this.props.incomeStatement.tax))
      ? parseFloat(this.props.incomeStatement.tax)
      : 0;

    let changeInEquity = annualResult;
    changeInEquity -= !isNaN(parseFloat(this.props.incomeStatement.dividends))
      ? parseFloat(this.props.incomeStatement.dividends)
      : 0;

    return (
      <Table bordered>
        <tbody>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Revenues" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.revenues}
                onChange={(value) => this.handleChange("revenues", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Cost of Sales / Service" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.costOfSales}
                onChange={(value) => this.handleChange("costOfSales", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Gross profit" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={grossProfit} disabled />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Personnel Cost Manager" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.personnelCostManager}
                onChange={(value) => this.handleChange("personnelCostManager", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: HQ (Other Costs)" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.hq}
                onChange={(value) => this.handleChange("hq", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Marketing" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.marketing}
                onChange={(value) => this.handleChange("marketing", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Indirect Sales Cost" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.indirectSalesCost}
                onChange={(value) => this.handleChange("indirectSalesCost", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: HR Development Costs" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.hrDevelopmentCosts}
                onChange={(value) => this.handleChange("hrDevelopmentCosts", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Severance Costs" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.severanceCosts}
                onChange={(value) => this.handleChange("severanceCosts", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Lease" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.lease}
                onChange={(value) => this.handleChange("lease", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Total Overheads" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={total} disabled />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="EBITDA" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={EBITDA} disabled />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Depreciation Property" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.property}
                onChange={(value) => this.handleChange("property", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Equipment Depreciation" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.equipment}
                onChange={(value) => this.handleChange("equipment", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Other Depreciation" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.other}
                onChange={(value) => this.handleChange("other", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="EBIT" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={ebit} disabled />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Interest" /> (5%)</div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.interest}
                onChange={(value) => this.handleChange("interest", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Earning Before Tax" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={earningBeforeTax} disabled />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Tax" /> (30%)</div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.tax}
                onChange={(value) => this.handleChange("tax", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Annual Result (Profit / Loss)" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={annualResult} disabled />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Dividends" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.incomeStatement.dividends}
                onChange={(value) => this.handleChange("dividends", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Change In Equity" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={changeInEquity} disabled />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default IncomeStatement;
