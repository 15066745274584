import React from "react";
// images
import ContainerField from "../draggables/ContainerField";

import Translate from "../../utils/Translate";

export default function Service({
  canEdit,
  service,
  serviceIndex,
  dispatch,
  regionIndex,
  coinsCount,
}) {
  return (
    <div className="service-block">
      <div>
        <div><Translate alias="Board" word="Service" /> {serviceIndex + 1}</div>
        <div>
          {service.map((containerItem, index) => (
            <ContainerField
              coinsCount={coinsCount}
              containerItem={containerItem}
              index={index}
              key={index}
              serviceIndex={serviceIndex}
              regionIndex={regionIndex}
              dispatch={dispatch}
              isVertical
              customClass={"service-cell"}
              canEdit={canEdit}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
