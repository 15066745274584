import React from "react";

// Images
import Property from "../../assets/svg/building.svg";
import Equipment from "../../assets/svg/gears.svg";
import Other from "../../assets/svg/other.svg";
import Overheads from "../../assets/svg/overheads.svg";
import RD from "../../assets/svg/r&d-cost.svg";
import OtherCosts from "../../assets/svg/other-cost.svg";
import OtherIncome from "../../assets/svg/other-income.svg";
import Marketing from "../../assets/svg/marketing.svg";
import IndirectSales from "../../assets/svg/indirect-sales.svg";
import HR from "../../assets/svg/hr.svg";
import Severance from "../../assets/svg/severance.svg";
import Lease from "../../assets/svg/lease.svg";
import Interest from "../../assets/svg/interest.svg";
import Dividends from "../../assets/svg/dividends.svg";
import Tax from "../../assets/svg/tax.svg";
import CostOfSales from "../../assets/svg/money.svg";
import Trade from "../../assets/svg/trade.svg";
import Pig from "../../assets/svg/pig.svg";

import DraggableContainer from "./DraggableContainer";
import items from "../../utils/items";
import { useDrop } from "react-dnd";
import RealtimeConnection from "../../utils/RealtimeConnection";

import Translate from "../../utils/Translate";

const components = {
  "Property Depreciation": Property,
  Property: Property,
  "Equipment Depreciation": Equipment,
  Equipment: Equipment,
  "Other Depreciation": Other,
  "Overheads Personnel": Overheads,
  "R&D Costs": RD,
  "Other Costs": OtherCosts,
  "Other Income": OtherIncome,
  Marketing: Marketing,
  "Indirect Sales Cost": IndirectSales,
  "HR Development": HR,
  Severance: Severance,
  Lease: Lease,
  Interest: Interest,
  Dividends: Dividends,
  Tax: Tax,
  "Cost of Sales": CostOfSales,
  "Trade Receivables": Trade,
  Cash: Pig,
};

export default function ContainerField({
  containerItem,
  index,
  customClass,
  alias,
  dispatch,
  branch,
  serviceIndex,
  regionIndex,
  isVertical,
  coinsCount,
  hub,
  canEdit,
}) {
  const [{ isOver, canDrop, containerId }, drop] = useDrop({
    accept: items.container,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      containerId: monitor.getHandlerId(),
    }),
    drop: (item, monitor) => {
      if (monitor.getHandlerId() === containerItem.containerId) {
        return {};
      }
      const obj = {
        alias,
        branch,
        index,
        serviceIndex,
        regionIndex,
        coins: item.coins ? item.coins : [],
        hub,
      };
      RealtimeConnection.addContainer(obj);
      return { success: true };
    },
  });
  const canPlayerDrop = canEdit && !containerItem.hasContainer ? drop : null;
  const SVG = components[containerItem.name];

  return (
    <>
      <div
        className={
          containerItem.hasContainer
            ? `block-container p-0 o-1 ${customClass}`
            : `block-container ${customClass}`
        }
        ref={canPlayerDrop}
      >
        {containerItem.hasContainer ? (
          <DraggableContainer
            branch={branch}
            serviceIndex={serviceIndex}
            coinsCount={coinsCount}
            regionIndex={regionIndex}
            alias={alias}
            containerItem={containerItem}
            fieldIndex={index}
            isVertical={isVertical}
            dispatch={dispatch}
            hub={hub}
            property={containerItem.name?.includes("Property")}
            canEdit={canEdit}
          />
        ) : (
          <>{containerItem.name !== undefined && <img src={SVG} style={{ maxHeight: "100%" }} />}</>
        )}
        {alias !== "cash" && containerItem.name && <span><Translate alias="Board" word={containerItem.name} /></span>}
        {index == 3 && alias === "cash" && containerItem.name && <span><Translate alias="Board" word={containerItem.name} /></span>}
      </div>
      {alias === "depreciation" && index >= 9 && index % 2 === 1 && <div />}
    </>
  );
}
