import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images

import silver from "../../assets/images/white-avatar.png";
import silverPreview from "../../assets/images/white-avatar-preview.png";

import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableSilverAvatar({ canEdit, count }) {
  const [, drag, preview] = useDrag({
    item: {
      type: "Silver",
      count,
      main: true,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            type: "silverAvatar",
          };
          RealtimeConnection.decreaseTeamTable(obj);
        }
      }
    },
  });

  const canDrag = canEdit ? drag : null;

  return (
    <>
      <DragPreviewImage connect={preview} src={silverPreview} />
      <div className="draggable-coins-container" ref={canDrag}>
        <img alt="draggable-coins" className="draggable-coins" src={silver} />
      </div>
    </>
  );
}
