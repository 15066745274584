const items = {
  container: "Container",
  coin: "Coin",
  time: "Time",
  pin: "Pin",
  avatar: {
    0: "Yellow",
    1: "Green",
    2: "Blue",
  },
  token: {
    5: "Token5",
    10: "Token10",
  },
};

export default items;
