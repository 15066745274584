const sessionState = {
  files: [],
  isChatOpened: false,
  messages: [],
  checkList: {},
  selectedLang: "en",
  formSelectedYear: 0,
  year: 0,
  formStatus: {
    planningSheet: false,
    annualReport: false,
    strategyPaper: false,
  },
};

export default sessionState;
