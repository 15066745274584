import React, { useState } from "react";
import InputNumber from "rc-input-number";
import ContainerField from "./draggables/ContainerField";
// Css
import "../assets/css/left-block.css";
// images
import { ReactComponent as Central } from "../assets/svg/central-hub.svg";
import { ReactComponent as Low } from "../assets/svg/low-hub.svg";
import { ReactComponent as HubLink } from "../assets/svg/link.svg";
import { ReactComponent as RightHubLink } from "../assets/svg/right-link.svg";
import { Collapse, Button } from "reactstrap";
import PinContainer from "./draggables/PinContainer";
import TokenContainer from "./draggables/TokenContainer";
import RealtimeConnection from "../utils/RealtimeConnection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Translate from "../utils/Translate";

export default function LeftBlock({ canEdit, state, dispatch, coinsCount, pinsCount }) {
  const [isOpenFirst, setIsOpenFirst] = useState(true);
  const [isOpenSecond, setIsOpenSecond] = useState(true);
  const [isOpenThird, setIsOpenThird] = useState(true);

  const toggleFirst = () => setIsOpenFirst(!isOpenFirst);
  const toggleSecond = () => setIsOpenSecond(!isOpenSecond);
  const toggleThird = () => setIsOpenThird(!isOpenThird);

  const changeEquity = (value) => {
    if (canEdit) {
      RealtimeConnection.addEquity({
        value: value,
      });
    }
  };
  return (
    <div style={{ margin: "0 22px" }}>
      <div className="left-block">
        <div>
          <span><Translate alias="Board" word="HEADQUARTERS" /></span>
        </div>
        <Button className="button-header" style={{ width: "90%" }} onClick={toggleFirst}>
          <span><Translate alias="Board" word="Finance" /></span>
          <FontAwesomeIcon icon={isOpenFirst ? faChevronDown : faChevronRight} />
        </Button>
        <Collapse isOpen={isOpenFirst} style={{ paddingLeft: "35px" }}>
          <div className="d-flex justify-content-between">
            <div>
              <div className="left-block-grid">
                {state.cash.map((containerItem, index) => (
                  <ContainerField
                    coinsCount={coinsCount}
                    dispatch={dispatch}
                    branch="leftBlock"
                    containerItem={containerItem}
                    index={index}
                    key={index}
                    alias="cash"
                    customClass="pig"
                    isVertical
                    canEdit={canEdit}
                  />
                ))}
              </div>
            </div>
            <div className="equity-container">
              <div className="block-container justify-content-center p-1">
                <InputNumber
                  className="w-100"
                  max="999"
                  min="-999"
                  precision="1"
                  name="equity"
                  value={state.equity !== 0 ? state.equity : null}
                  onChange={(value) => changeEquity(value)}
                />
                <span><Translate alias="Board" word="Equity" /></span>
              </div>

              <TokenContainer
                checked={state.credit5.checked}
                initialValue={state.credit5.value}
                tokenType={5}
                canEdit={canEdit}
              />
              <TokenContainer
                checked={state.credit10.checked}
                initialValue={state.credit10.value}
                tokenType={10}
                canEdit={canEdit}
              />
            </div>
          </div>
          <div>
            <ContainerField
              coinsCount={coinsCount}
              dispatch={dispatch}
              branch="leftBlock"
              containerItem={state.tradeReceivables}
              alias={"tradeReceivables"}
              customClass="w-h"
              canEdit={canEdit}
            />
          </div>
        </Collapse>

        <Button
          className="button-header"
          onClick={toggleSecond}
          style={{ borderTop: "1px solid #203b52", width: "90%" }}
        >
          <span><Translate alias="Board" word="FIXED ASSETS" /></span>
          <FontAwesomeIcon icon={isOpenSecond ? faChevronDown : faChevronRight} />
        </Button>
        <Collapse
          isOpen={isOpenSecond}
          className="gears-block-grid"
          style={{ paddingLeft: "35px" }}
        >
          {state.fixedAssets.map((containerItem, index) => (
            <ContainerField
              coinsCount={coinsCount}
              dispatch={dispatch}
              branch="leftBlock"
              containerItem={containerItem}
              index={index}
              key={index}
              alias="fixedAssets"
              canEdit={canEdit}
            />
          ))}
        </Collapse>

        <Button
          className="button-header"
          onClick={toggleThird}
          style={{ borderTop: "1px solid #203b52", width: "90%" }}
        >
          <span><Translate alias="Board" word="Services" /></span>
          <FontAwesomeIcon icon={isOpenThird ? faChevronDown : faChevronRight} />
        </Button>
        <Collapse isOpen={isOpenThird} style={{ paddingLeft: "35px", border: 0 }}>
          {state.services.map((service, index) => (
            <div className="left-service-bar" key={index}>
              <div>
                <span><Translate alias="Board" word="Service" /></span> <span style={{ fontSize: "25px" }}>{service.id}</span>
              </div>
              <div>
                {service.pins.map((pin, indexPin) => (
                  <PinContainer
                    key={indexPin}
                    index={indexPin}
                    serviceIndex={index}
                    checked={pin.checked}
                    dispatch={dispatch}
                    canEdit={canEdit}
                    pinsCount={pinsCount}
                  />
                ))}
              </div>
              <ContainerField
                coinsCount={coinsCount}
                dispatch={dispatch}
                branch="leftBlock"
                containerItem={service}
                alias={"services"}
                index={index}
                customClass="rectangle"
                canEdit={canEdit}
              />
            </div>
          ))}
        </Collapse>
      </div>

      <div style={{ position: "relative" }}>
        <div className="left-hub-link">
          <HubLink />
        </div>
        <div className="right-hub-link">
          <RightHubLink />
        </div>
        <div className="last-left-bar">
          <div style={{ marginTop: "-75px" }}>
            <div>
              <span><Translate alias="Board" word="CENTRAL SERVICE HUB" /></span>
              <div>
                {state.centralHub.pins.map((pin, y) => (
                  <PinContainer
                    key={y}
                    index={y}
                    checked={pin.checked}
                    alias="centralHub"
                    canEdit={canEdit}
                    pinsCount={pinsCount}
                  />
                ))}
                <div className="centralSvg">
                  <Central />
                  <Central />
                </div>
              </div>
            </div>
            <div>
              {state.centralHub.containers.map((containerItem, index) => (
                <ContainerField
                  coinsCount={coinsCount}
                  dispatch={dispatch}
                  branch="leftBlock"
                  containerItem={containerItem}
                  index={index}
                  key={index}
                  customClass={"service-cell"}
                  alias="centralHub"
                  isVertical
                  hub={true}
                  canEdit={canEdit}
                />
              ))}
            </div>
          </div>
          <div>
            <div>
              <span><Translate alias="Board" word="Low Cost SERVICE HUB" /></span>
              <div>
                {state.lowCostHub.pins.map((pin, y) => (
                  <PinContainer
                    key={y}
                    index={y}
                    checked={pin.checked}
                    alias="lowCostHub"
                    canEdit={canEdit}
                    pinsCount={pinsCount}
                  />
                ))}
                <div className="lowSvg">
                  <Low />
                  <Low />
                </div>
              </div>
            </div>
            <div>
              {state.lowCostHub.containers.map((containerItem, index) => (
                <ContainerField
                  coinsCount={coinsCount}
                  dispatch={dispatch}
                  branch="leftBlock"
                  containerItem={containerItem}
                  index={index}
                  key={index}
                  customClass={"service-cell"}
                  alias="lowCostHub"
                  isVertical
                  hub={true}
                  canEdit={canEdit}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
