import React from "react";
import { Table, FormControl } from "react-bootstrap";
import InputNumber from "rc-input-number";
import RealtimeConnection from "../../../utils/RealtimeConnection";

import Translate from "../../../utils/Translate";

class CashFlow extends React.Component {
  handleChange(field, value) {
    if (this.props.canEdit) {
      RealtimeConnection.changeAnnualReport({
        year: this.props.year,
        groupName: "cashFlow",
        field: field,
        value: value,
      });
    }
  }

  render() {
    let cashAtTheBeginning = !isNaN(parseFloat(this.props.cashFlow.cashLevelAtStart))
      ? parseFloat(this.props.cashFlow.cashLevelAtStart)
      : 0;

    let operationCash = !isNaN(parseFloat(this.props.cashFlow.revenues))
      ? parseFloat(this.props.cashFlow.revenues)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.costOfSales))
      ? parseFloat(this.props.cashFlow.costOfSales)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.personnelCostManager))
      ? parseFloat(this.props.cashFlow.personnelCostManager)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.hq))
      ? parseFloat(this.props.cashFlow.hq)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.marketing))
      ? parseFloat(this.props.cashFlow.marketing)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.indirectSalesCost))
      ? parseFloat(this.props.cashFlow.indirectSalesCost)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.severanceCosts))
      ? parseFloat(this.props.cashFlow.severanceCosts)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.hrDevelopmentCosts))
      ? parseFloat(this.props.cashFlow.hrDevelopmentCosts)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.lease))
      ? parseFloat(this.props.cashFlow.lease)
      : 0;
    operationCash -= !isNaN(parseFloat(this.props.cashFlow.tax))
      ? parseFloat(this.props.cashFlow.tax)
      : 0;

    let investmentCash = 0;
    investmentCash -= !isNaN(parseFloat(this.props.cashFlow.property))
      ? parseFloat(this.props.cashFlow.property)
      : 0;
    investmentCash -= !isNaN(parseFloat(this.props.cashFlow.equipment))
      ? parseFloat(this.props.cashFlow.equipment)
      : 0;
    investmentCash -= !isNaN(parseFloat(this.props.cashFlow.costs))
      ? parseFloat(this.props.cashFlow.costs)
      : 0;
    investmentCash += !isNaN(parseFloat(this.props.cashFlow.assets))
      ? parseFloat(this.props.cashFlow.assets)
      : 0;

    let financingCash = !isNaN(parseFloat(this.props.cashFlow.changeInCredit))
      ? parseFloat(this.props.cashFlow.changeInCredit)
      : 0;
    financingCash -= !isNaN(parseFloat(this.props.cashFlow.interest))
      ? parseFloat(this.props.cashFlow.interest)
      : 0;
    financingCash -= !isNaN(parseFloat(this.props.cashFlow.dividends))
      ? parseFloat(this.props.cashFlow.dividends)
      : 0;

    let cashAtTheEnd = cashAtTheBeginning + (operationCash + investmentCash + financingCash);

    return (
      <Table bordered>
        <tbody>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Cash at the beginning of year" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.cashLevelAtStart}
                onChange={(value) => this.handleChange("cashLevelAtStart", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Revenues" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.revenues}
                onChange={(value) => this.handleChange("revenues", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Cost of Sales / Service" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.costOfSales}
                onChange={(value) => this.handleChange("costOfSales", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Personnel Cost Manager" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.personnelCostManager}
                onChange={(value) => this.handleChange("personnelCostManager", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: HQ (Other Costs)" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.hq}
                onChange={(value) => this.handleChange("hq", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Marketing" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.marketing}
                onChange={(value) => this.handleChange("marketing", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Indirect Sales Cost" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.indirectSalesCost}
                onChange={(value) => this.handleChange("indirectSalesCost", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: HR Development Costs" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.hrDevelopmentCosts}
                onChange={(value) => this.handleChange("hrDevelopmentCosts", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Severance Costs" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.severanceCosts}
                onChange={(value) => this.handleChange("severanceCosts", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Overhead: Lease" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.lease}
                onChange={(value) => this.handleChange("lease", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Tax" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.tax}
                onChange={(value) => this.handleChange("tax", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Total Cash Flow from Operation" /></div>
            </td>
            <td>
              <InputNumber className="annualField" value={operationCash} disabled />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Investments in Property" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.property}
                onChange={(value) => this.handleChange("property", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Investments in Equipment" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.equipment}
                onChange={(value) => this.handleChange("equipment", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Investments in Cap. Dev. Costs" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.costs}
                onChange={(value) => this.handleChange("costs", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Disposal of Assets" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.assets}
                onChange={(value) => this.handleChange("assets", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Total Cash Flow from Investments" /></div>
            </td>
            <td>
              <InputNumber className="annualField" value={investmentCash} disabled />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Change In Credit" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.changeInCredit}
                onChange={(value) => this.handleChange("changeInCredit", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Interest" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.interest}
                onChange={(value) => this.handleChange("interest", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Dividends" /></div>
            </td>
            <td>
              <InputNumber
                className="annualField"
                value={this.props.cashFlow.dividends}
                onChange={(value) => this.handleChange("dividends", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Total Cash Flow from Financing" /></div>
            </td>
            <td>
              <InputNumber className="annualField" value={financingCash} disabled />
            </td>
          </tr>

          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Cash at the end of the year" /></div>
            </td>
            <td>
              <InputNumber className="annualField" value={cashAtTheEnd} disabled />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default CashFlow;
