import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";
import { ReactComponent as Credit } from "../../assets/svg/credit.svg";
import DraggableToken from "./DraggableToken";

import Translate from "../../utils/Translate";

export default function TokenContainer({ canEdit, tokenType, checked, initialValue }) {
  const [{ isOver, containerId }, drop] = useDrop({
    accept: items.token[tokenType],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      containerId: monitor.getHandlerId(),
    }),
    drop: (item) => {
      const obj = {
        tokenType,
        value: initialValue > 0 ? item.value + initialValue : item.value,
      };
      if (item.containerId !== containerId) {
        RealtimeConnection.addToken(obj);
      }
      return { success: true };
    },
  });

  const canDrop = canEdit ? drop : null;

  return (
    <div className={checked ? "block-container p-0" : "block-container"} ref={canDrop}>
      {checked ? (
        <DraggableToken
          tokenType={tokenType}
          value={initialValue}
          canEdit={canEdit}
          checked={checked}
          containerId={containerId}
        />
      ) : (
        <>
          <Credit />
          <span><Translate alias="Board" word={ `Credit ${tokenType}%` } /></span>
        </>
      )}
    </div>
  );
}
