import React, { useEffect, useState } from "react";
import { Table, Row, Col, Container, Button } from "reactstrap";

// Images
import container from "../../assets/images/container-h.png";
import containerFlipped from "../../assets/images/_box-container-flipped.png";
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../assets/images/dollar_coin_bronze-preview.png";

// Utils
import RealtimeConnection from "../../utils/RealtimeConnection";

// CSS
import "../../assets/css/ContainerModal.css";

const formatNumber = new Intl.NumberFormat("en-US");
export default function ContainerModal({
  toggle,
  coins,
  fieldIndex,
  alias,
  branch,
  serviceIndex,
  regionIndex,
  coinsCount,
  property,
  rented,
  hub,
  canEdit,
}) {
  const [showAddCashQuestion, setShowAddCashQuestion] = useState(false);
  const [showSubtractCashQuestion, setShowSubtractCashQuestion] = useState(false);
  const [cashValueInput, setCashValueInput] = useState("");
  const [msg, setMsg] = useState(false);
  const [err, setErr] = useState("");

  useEffect(() => {
    if (showAddCashQuestion) {
      setShowSubtractCashQuestion(false);
    }
  }, [showAddCashQuestion]);
  useEffect(() => {
    if (showSubtractCashQuestion) {
      setShowAddCashQuestion(false);
    }
  }, [showSubtractCashQuestion]);

  const getCoinRow = (key, imgSrc, count, value) => {
    return (
      <tr key={key}>
        <td>
          <img src={imgSrc} className="prevCoinImg" alt="" />
        </td>
        <td>{count}</td>
        <td>{formatNumber.format(parseFloat(value) * count)}</td>
      </tr>
    );
  };

  const addCoinsAction = (currentCoins) => {
    let cashValue = parseFloat(cashValueInput);
    cashValue = Math.ceil(cashValue * 2) / 2;

    if (coinsCount >= cashValue) {
      setErr("");
      let currentCoinsValue = 0;
      for (let i = 0; i < currentCoins.length; i++) {
        currentCoinsValue += currentCoins[i];
      }

      let newCashValue = currentCoinsValue + cashValue;
      let newCoins = [];
      while (newCashValue > 0) {
        if (newCashValue >= 10) {
          newCoins.push(10);
          newCashValue -= 10;
        } else if (newCashValue >= 1) {
          newCoins.push(1);
          newCashValue -= 1;
        } else {
          newCoins.push(0.5);
          newCashValue -= 0.5;
        }
      }

      if (newCoins.length > 0) {
        if (newCoins.length <= 20) {
          setShowAddCashQuestion(false);

          RealtimeConnection.addContainer({
            alias,
            branch,
            index: fieldIndex,
            serviceIndex,
            regionIndex,
            coins: newCoins,
            hub,
          });

          RealtimeConnection.decreaseTeamTable({
            type: "coins",
            value: cashValue,
          });
          toggle();
        }
      }
    } else {
      setErr("Not Enough Cash");
    }
  };

  const subtractCoinsAction = (currentCoins) => {
    if (currentCoins.length > 0 && cashValueInput > 0) {
      let cashValue = parseFloat(cashValueInput);
      cashValue = Math.ceil(cashValue * 2) / 2;

      let currentCoinsValue = 0;
      for (let i = 0; i < currentCoins.length; i++) {
        currentCoinsValue += currentCoins[i];
      }

      if (currentCoinsValue >= cashValue) {
        let newCashValue = currentCoinsValue - cashValue;
        let newCoins = [];
        while (newCashValue > 0) {
          if (newCashValue >= 10) {
            newCoins.push(10);
            newCashValue -= 10;
          } else if (newCashValue >= 1) {
            newCoins.push(1);
            newCashValue -= 1;
          } else {
            newCoins.push(0.5);
            newCashValue -= 0.5;
          }
        }

        if (newCoins.length <= 20) {
          setShowSubtractCashQuestion(false);

          // Update Socket
          RealtimeConnection.addContainer({
            alias,
            branch,
            index: fieldIndex,
            serviceIndex,
            regionIndex,
            hub,
            coins: newCoins,
          });
          RealtimeConnection.increaseTeamTable({
            type: "coins",
            value: cashValue,
          });
          toggle();
        }
      }
    }
  };

  const changeLocationStatus = (status) => {
    RealtimeConnection.changeLocationStatus({
      alias,
      branch,
      index: fieldIndex,
      serviceIndex,
      regionIndex,
      hub,
      value: status === "Rent" ? true : false,
      coins: [],
    });
    if (status === "Rent") {
      let sum = coins.reduce((a, b) => a + b, 0);
      RealtimeConnection.increaseTeamTable({
        type: "coins",
        value: sum,
      });
    }
  };
  let coinsList = {};
  coins.forEach((value) => {
    coinsList[`${value}`] = coinsList[`${value}`] ? coinsList[`${value}`] + 1 : 1;
  });

  let coinsRows = [];
  let totalCoinsValue = 0;
  let totalCoinsCount = 0;

  if (coinsList[`10`]) {
    totalCoinsValue += 10 * parseFloat(coinsList[`10`]);
    totalCoinsCount += parseFloat(coinsList[`10`]);

    coinsRows.push(getCoinRow(0, previewGoldCoinImg, coinsList[`10`], 10));
  }
  if (coinsList[`1`]) {
    totalCoinsValue += 1 * parseFloat(coinsList[`1`]);
    totalCoinsCount += parseFloat(coinsList[`1`]);

    coinsRows.push(getCoinRow(1, previewSilverCoinImg, coinsList[`1`], 1));
  }
  if (coinsList[`0.5`]) {
    totalCoinsValue += 0.5 * parseFloat(coinsList[`0.5`]);
    totalCoinsCount += parseFloat(coinsList[`0.5`]);

    coinsRows.push(getCoinRow(2, previewBronzeCoinImg, coinsList[`0.5`], 0.5));
  }
  return (
    <>
      <div className="containerModalObjClass">
        {rented ? (
          <img className="objModelClass" src={containerFlipped} alt="" />
        ) : (
          <img className="objModelClass" src={container} alt="" />
        )}

        {property && canEdit && (
          <Container style={{ margin: "0", width: "30%" }}>
            {rented ? (
              <Row>
                <Col>
                  <Button
                    className="purchase-btn"
                    onClick={() => {
                      changeLocationStatus("Purchase");
                      setMsg(true);
                    }}
                    size="sm"
                  >
                    Purchase
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginTop: "5px" }}>
                <Col>
                  <Button
                    className="rent-btn"
                    onClick={() => {
                      changeLocationStatus("Rent");
                      setMsg(false);
                    }}
                    size="sm"
                  >
                    Rent
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        )}

        <div className="coins-container">
          <div className="helper"></div>
          {coins.map((value, index) => {
            const isLastCoin = coins.length === index + 1 && (index + 1) % 5 === 0 ? true : false;
            let coinClass = isLastCoin ? "last-coin " : "not-last-coin ";
            switch (value) {
              case 10:
                coinClass += "gold";
                break;
              case 1:
                coinClass += "silver";
                break;
              case 0.5:
                coinClass += "bronze";
                break;
              default:
                break;
            }
            return <div key={ index } className={["coin", coinClass].join(" ")} />;
          })}
        </div>
      </div>
      <div className="clearfix"></div>
      {msg && (
        <div style={{ textAlign: "center", color: "red" }}>Make sure to make an investment</div>
      )}
      <div style={{ height: 20 }}></div>
      <Table striped bordered>
        <thead>
          <tr style={{ color: "white", backgroundColor: "#002955" }}>
            <th>Value</th>
            <th>Count</th>
            <th>Cash</th>
          </tr>
        </thead>
        <tbody>{coinsRows}</tbody>
        <tbody>
          <tr>
            <td className="bold-text">Total</td>
            <td className="bold-text">{totalCoinsCount}</td>
            <td className="bold-text">{formatNumber.format(totalCoinsValue)}</td>
          </tr>
        </tbody>
      </Table>
      <div className="horizontal-separator">&nbsp;</div>
      {!rented && canEdit && (
        <div className="container-actions">
          <Container>
            <Row>
              <Col xs={1}></Col>
              <Col>
                <Button
                  className="purchase-btn"
                  onClick={() => setShowAddCashQuestion(true)}
                  size="sm"
                >
                  Add
                </Button>
              </Col>
              <Col xs={2}></Col>
              <Col>
                <Button
                  className="rent-btn"
                  onClick={() => setShowSubtractCashQuestion(true)}
                  size="sm"
                >
                  Subtract
                </Button>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row>
              <Col className="questions-container">
                {showAddCashQuestion ? (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      {err && err}
                    </div>
                    <div className="question-text">How much cash do you want to add?</div>
                    <div className="question-input">
                      <input
                        type="text"
                        autoFocus
                        id="units"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        name="units"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            addCoinsAction(coins);
                          }
                        }}
                        onChange={(e) => setCashValueInput(e.target.value)}
                      />
                    </div>
                    <div className="question-btn">
                      <Button
                        className="purchase-btn"
                        size="sm"
                        onClick={() => addCoinsAction(coins)}
                      >
                        Confirm
                      </Button>
                    </div>
                  </>
                ) : null}
                {showSubtractCashQuestion ? (
                  <>
                    <div className="question-text">How much cash do you want to subtract?</div>
                    <div className="question-input">
                      <input
                        type="text"
                        autoFocus
                        id="units2"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        name="units2"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            subtractCoinsAction(coins);
                          }
                        }}
                        onChange={(e) => setCashValueInput(e.target.value)}
                      />
                    </div>
                    <div className="question-btn">
                      <Button
                        className="purchase-btn"
                        size="sm"
                        onClick={() => subtractCoinsAction(coins)}
                      >
                        Confirm
                      </Button>
                    </div>
                  </>
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}
