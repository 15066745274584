import React from "react";
import { useDrop } from "react-dnd";

// Images
import deleteImg from "../../assets/images/delete.png";
import deleteActive from "../../assets/images/delete-active.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableDelete({ canEdit }) {
  const [{ isOver }, drop] = useDrop({
    accept: [
      items.container,
      items.coin,
      items.avatar[0],
      items.avatar[1],
      items.avatar[2],
      "Silver",
      items.token[5],
      items.token[10],
      items.pin,
    ],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (item.type === items.container) {
        if (item.coins.length > 0) {
          let coinsValue = 0;
          item.coins.forEach((coin) => (coinsValue += coin));

          RealtimeConnection.increaseTeamTable({
            type: "coins",
            value: coinsValue,
          });
        }
        const obj = {
          alias: item.alias,
          branch: item.branch,
          index: item.index,
          serviceIndex: item.serviceIndex,
          regionIndex: item.regionIndex,
          hub: item.hub,
        };
        RealtimeConnection.removeContainer(obj);
        return { success: true };
      } else if (
        item.type === items.avatar[0] ||
        item.type === items.avatar[1] ||
        item.type === items.avatar[2] ||
        item.type === "Silver"
      ) {
        let avatarType = "";
        if (item.type === items.avatar[0]) {
          avatarType = "goldAvatar";
        } else if (item.type === items.avatar[2]) {
          avatarType = "blueAvatar";
        } else if (item.type === items.avatar[1]) {
          avatarType = "greenAvatar";
        } else if (item.type === "Silver") {
          avatarType = "silverAvatar";
        }
        if (avatarType !== "") {
          RealtimeConnection.increaseTeamTable({
            type: avatarType,
          });
        }
        return { success: true };
      } else if (item.type === items.token[5]) {
        RealtimeConnection.increaseTeamTable({
          type: "token5",
          value: item.value,
        });
        return { success: true };
      } else if (item.type === items.token[10]) {
        RealtimeConnection.increaseTeamTable({
          type: "token10",
          value: item.value,
        });
        return { success: true };
      } else if (item.type === items.pin) {
        RealtimeConnection.increaseTeamTable({
          type: "pins",
        });
        return { success: true };
      } else if (item.type === items.coin) {
        RealtimeConnection.increaseTeamTable({
          type: "coins",
          value: item.value,
        });
        return { success: true };
      }
    },
  });

  const canDrop = canEdit ? drop : null;
  return (
    <div className="removeDroppable" ref={canDrop}>
      <img alt="drop" src={isOver ? deleteActive : deleteImg} />
    </div>
  );
}
