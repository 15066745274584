import React from "react";
import { useDrag } from "react-dnd";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableToken({ canEdit, value, tokenType }) {
  const [, drag] = useDrag({
    item: {
      type: items.token[tokenType],
      value: value,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            type: tokenType === 5 ? "token5" : "token10",
            value: value,
          };
          RealtimeConnection.decreaseTeamTable(obj);
        }
      }
    },
  });

  const canDrag = canEdit ? drag : null;
  return (
    <div className="tokenDraggable" ref={canDrag}>
      <div className="Token-container-draggable">
        <div className="Token-value">{value}</div>
        <div className="Token-text"> Credit {tokenType}%</div>
      </div>
    </div>
  );
}
