import React, { useState } from "react";
import checkListIcon from "../../assets/images/checklist-icon.png";
import documentIcon from "../../assets/images/document-icon.png";
import urls from "../../utils/urls";
import checkListShort from "./Checklist";
import checkListLong from "./Checklist long";

import AnnualReport from "../Forms/AnnualReport";
import DecisionSheet from "../Forms/DecisionSheet";
import StrategyPaper from "../Forms/StrategyPaper";

import Translate from "../../utils/Translate";

export default function FixedRightBar({
	canEdit,
	dispatch,
	formStatus,
	decisionSheet,
	annualReport,
	strategyPaper,
	session,
	isSessionLong,
	checkList
}) {
	const [isMenuOpened, setMenuOpened] = useState(false);
	const [isCheckListOpened, setCheckListOpened] = useState(false);

	function openAnnual(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "annualReport", value: true });
	}
	function openStrategy(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "strategyPaper", value: true });
	}
	function openPlanning(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "decisionSheet", value: true });
	}

	function toggleFilesMenu() {
		if (isMenuOpened) {
			setMenuOpened(false);
			updateUI("FILES", "close");
		} else {
			setMenuOpened(true);
			setCheckListOpened(false);
			updateUI("FILES", "open");
		}
	}

	function toggleCheckListMenu() {
		if (isCheckListOpened) {
			setCheckListOpened(false);
			updateUI("CHECKLIST", "close");
		} else {
			setCheckListOpened(true);
			setMenuOpened(false);
			updateUI("CHECKLIST", "open");
		}
	}

	function updateUI(panelType, status) {
		const appContainer = document.getElementsByClassName("center-container");
		const filesContainer = document.getElementsByClassName("filesContainer");
		const checkListContainer = document.getElementsByClassName("checkListContainer");

		if (status === "close") {
			appContainer[0].classList.remove("moveRight");
			filesContainer[0].classList.remove("fullWidth");
			checkListContainer[0].classList.remove("fullWidth");
		} else {
			if (panelType === "FILES") {
				appContainer[0].classList.add("moveRight");
				window.scrollTo({
					left: 380,
					behavior: "smooth",
				});
				filesContainer[0].classList.add("fullWidth");
				checkListContainer[0].classList.remove("fullWidth");
			} else if (panelType === "CHECKLIST") {
				appContainer[0].classList.add("moveRight");
				window.scrollTo({
					left: 380,
					behavior: "smooth",
				});
				checkListContainer[0].classList.add("fullWidth");
				filesContainer[0].classList.remove("fullWidth");
			} else {
				this.setState({ isMenuOpened: false, isCheckListOpened: false }, () => {
					filesContainer[0].classList.remove("fullWidth");
					checkListContainer[0].classList.remove("fullWidth");
				});
			}
		}
	}
	//const checkList = isSessionLong ? checkListLong : checkListShort;
	return (
		<>
			<div className="files-library-btn">
				<div className="section" onClick={toggleCheckListMenu}>
					<div className="button checklist">
						<img alt="checkListIcon" src={checkListIcon} />
						<div className="text"><Translate alias="Board" word="Check List" /></div>
					</div>
				</div>
				<div className="section" onClick={toggleFilesMenu}>
					<div className="button">
						<img alt="documentIcon" src={documentIcon} />
						<div className="text"><Translate alias="Board" word="Files Library" /></div>
					</div>
				</div>
			</div>
			<div className="checkListContainer">
				<div className="files-header">
					<div className="files-header-logo">
						<img alt="checkListIcon" src={checkListIcon} />
						<div className="text"><Translate alias="Board" word="Check List" /></div>
					</div>
				</div>
				<div className="blur">
					{ 
						checkList.map(checklistHeader => {
							return (
								<div className="checkListRowContainer" key={checklistHeader.id}>
									<div className="checkListTitle">{checklistHeader.title}</div>
									{
										checklistHeader.SimserviceChecklists.map(checkList => {
											return (
												<div className="checkListRow" key={checkList.id}>
													<div className="checkListSubtitle">
														{ checkList.title }
													</div>
													<div className="checkListValue">
														<div dangerouslySetInnerHTML={{ __html: checkList.subtitle }} />
													</div>
													<div dangerouslySetInnerHTML={{ __html: checkList.description }} />
													<div className="kits-separator" />
												</div>
											);
										})
									}
								</div>
							);
						})
					}
					{ /*checkList.map((obj, index) => {
						return (
							<div className="checkListRowContainer" key={index}>
								<div className="checkListTitle">{obj.title}</div>
								{obj.list.map((listObj, index2) => {
									return (
										<div className="checkListRow" key={index2}>
											<div className="checkListSubtitle">
												{listObj.title.map((l, index) => (
													<React.Fragment key={index}>
														{l} <br />
													</React.Fragment>
												))}
											</div>
											<div className="checkListValue">
												{listObj.value.map((l, index) => (
													<React.Fragment key={index}>
														{l} <br />
													</React.Fragment>
												))}
											</div>
											<div>
												<ul>
													{listObj.actions.map((action, index3) => {
														return <li key={index3}>{action}</li>;
													})}
												</ul>
											</div>
											<div className="kits-separator" />
										</div>
									);
								})}
							</div>
						);
					})*/ }
				</div>
			</div>
			<div className="filesContainer">
				<div className="files-header">
					<div className="files-header-logo">
						<img alt="documentIcon" src={documentIcon} />
						<div className="text"><Translate alias="Board" word="Files Library" /></div>
					</div>
				</div>
				<div className="blur">
					{!formStatus.decisionSheet && !formStatus.annualReport && !formStatus.strategyPaper && (
						<div className="files-section">
							<div className="filesListTitle"><Translate alias="Forms" word="Forms" /></div>
							<div className="fileRow">
								<div className="file-link form-link" onClick={openPlanning}>
									<div><Translate alias="Forms" word="Decision Sheet" /></div>
								</div>
							</div>
							<div className="fileRow">
								<div className="file-link form-link" onClick={openAnnual}>
									<div><Translate alias="Forms" word="Annual Report" /></div>
								</div>
							</div>
							<div className="fileRow">
								<div className="file-link form-link" onClick={openStrategy}>
									<div><Translate alias="Forms" word="Strategy Paper" /></div>
								</div>
							</div>
						</div>
					)}

					{formStatus.annualReport && (
						<AnnualReport
							canEdit={canEdit}
							dispatch={dispatch}
							session={session}
							annualReport={annualReport}
						/>
					)}
					{formStatus.decisionSheet && (
						<DecisionSheet
							canEdit={canEdit}
							dispatch={dispatch}
							decisionSheet={decisionSheet}
							session={session}
							isSessionLong={isSessionLong}
						/>
					)}
					{formStatus.strategyPaper && (
						<StrategyPaper
							canEdit={canEdit}
							dispatch={dispatch}
							strategyPaper={strategyPaper}
							session={session}
							isSessionLong={isSessionLong}
						/>
					)}
					{session.files && session.files.length > 0 && (
						<div className="files-section">
							<div className="filesListTitle"><Translate alias="Board" word="Files" /></div>
							{session.files.map((obj, index) => {
								return (
									<div className="fileRow" key={index}>
										<div className="file-link">
											<a
												href={`${urls.backendURL}/uploads/sessions/images/${obj.file}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{obj.name}
											</a>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
