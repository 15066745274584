import React from "react";

import { Container, Row, Col, Table, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";

import RealtimeConnection from "../../utils/RealtimeConnection";
import InputNumber from "rc-input-number";
import "../../assets/css/Forms.css";

import Translate from "../../utils/Translate";

const subText = {
  1: "($7 - $10)",
  2: "($5 - $8)",
  3: "($9 - $13)",
};
export default function DecisionSheet({
  canEdit,
  dispatch,
  decisionSheet,
  session,
  isSessionLong,
}) {
  function closeDecision(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch({ type: "decisionSheet", value: false });
  }

  function handleChange(regionIndex, serviceIndex, itemIndex, value) {
    RealtimeConnection.changeDecisionSheet({
      year: session.formSelectedYear,
      regionIndex,
      serviceIndex,
      itemIndex,
      value,
    });
  }
  function changeFormYear(value) {
    RealtimeConnection.changeFormYear({
      year: value,
      type: "decisionSheet",
    });
  }
  let yearsOptions = [];
  for (let i = 0; i <= session.year; i++) {
    yearsOptions.push(
      <Dropdown.Item key={i} eventKey={i} onClick={() => changeFormYear(i)}>
        <Translate alias="Board" word="Year" /> {i}
      </Dropdown.Item>
    );
  }

  let formStatus = canEdit ? true : false;
  if (session.formSelectedYear !== session.year) {
    formStatus = false;
  }

  return (
    <Container className="formContainer">
      <Row>
        <Col>
          <div className="formHeader">
            <div className="headerTitle bold"><Translate alias="Forms" word="Decision Sheet" /></div>
            <DropdownButton
              as={ButtonGroup}
              variant="outline-secondary"
              title={`Year ${session.formSelectedYear}`}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              {yearsOptions}
            </DropdownButton>
            <div className="closeForm" onClick={closeDecision}>
              X
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table bordered>
            <tbody>
              {decisionSheet.map((region, regionIndex) => (
                <tr key={regionIndex}>
                  <td>
                    <div className="bold">
                      <Translate alias="Forms" word="Region" />
                      {` ${region.id}`}
                    </div>
                  </td>
                  <td>
                    {region.services.map((service, serviceIndex) => {
                      const text = subText[serviceIndex + 1];
                      return (
                        <tr key={serviceIndex}>
                          <div className="bold border-bottom">
                            <Translate alias="Forms" word="Service" />
                            { ` ${serviceIndex + 1 + " " + text}` }
                          </div>
                          {service.map((item, itemIndex) => (
                            <tr key={itemIndex}>
                              <td style={{ lineHeight: "2.2" }}>
                                <div className="bold ">
                                  <Translate alias="Forms" word={ item.name } />
                                </div>
                              </td>
                              <td>
                                <InputNumber
                                  min="0"
                                  className="annualField"
                                  value={item.value}
                                  onChange={(value) =>
                                    handleChange(regionIndex, serviceIndex, itemIndex, value)
                                  }
                                  disabled={!canEdit}
                                />
                              </td>
                            </tr>
                          ))}
                        </tr>
                      );
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
