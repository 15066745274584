import React from "react";
import { useDrop } from "react-dnd";
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";
import DraggablePin from "./DraggablePin";

export default function PinContainer({
  regionIndex,
  dispatch,
  index,
  checked,
  serviceIndex,
  alias,
  canEdit,
  pinsCount,
}) {
  const [{ isOver }, drop] = useDrop({
    accept: items.pin,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      containerId: monitor.getHandlerId(),
    }),
    drop: (item) => {
      if (!checked && pinsCount > 0) {
        const obj = {
          regionIndex,
          index,
          serviceIndex,
          alias,
        };
        RealtimeConnection.addPin(obj);
        return { success: true };
      }
    },
  });
  const canDrop = canEdit ? drop : null;
  return (
    <div
      ref={canDrop}
      className={regionIndex === undefined && !checked ? "employee-circle o-7" : "employee-circle"}
      style={regionIndex !== undefined ? { backgroundColor: "#C0C6CA", marginLeft: "10px" } : null}
    >
      {checked && (
        <DraggablePin
          regionIndex={regionIndex}
          serviceIndex={serviceIndex}
          index={index}
          dispatch={dispatch}
          alias={alias}
          canEdit={canEdit}
        />
      )}
    </div>
  );
}
