import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image } from "react-bootstrap";

import "./App.css";
import SimService from "./components/SimService";
import { StateProvider } from "./components/stateContext";
import HomeComponent from "./components/Auth/HomeComponent";

// Constant
import URLs from "./utils/urls";

// Images
import loaderImg from "./assets/images/loader.gif";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function findGetParameter(parameterName) {
  let result = null,
    tmp = [];
  let items = window.location.search.substr(1).split("&");
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

window.sessionId = parseInt(findGetParameter("session_id"));
window.playerId = parseInt(findGetParameter("user_id"));
window.adminId = parseInt(findGetParameter("admin_id"));
window.teamId = parseInt(findGetParameter("team_id"));
window.token = findGetParameter("token");

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSessionLong, setIsSessionLong] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [authorizeMessage, setAuthorizeMessage] = useState("");
  const [languages, setLanguages] = useState([]);

  const checkAuth = async () => {
    const result = await axios.post(
      URLs.authenticate,
      {
        playerId: !isNaN(window.playerId) ? window.playerId : 0,
        adminId: !isNaN(window.adminId) ? window.adminId : 0,
        teamId: window.teamId,
        token: window.token,
        sessionId: window.sessionId,
        langId: localStorage.langId,
      },
      { headers: { "content-type": "application/json" } }
    );

    if (result.data.success) {
      let response = result.data;
      if (!isNaN(window.adminId)) {
        window.playerId = window.adminId;
        window.playerName = response.player.firstName + " " + response.player.lastName;
      } else {
        window.playerName = response.player.name;
      }
      window.staticWords = response.localizations.staticWords;
      
      setLanguages(response.languages);
      setIsAuthorized(true);
      setIsSessionLong(response.long);
      setTeamName(response.teamData ? response.teamData.name : response.player.Teams[0].name);
      setIsAdmin(!isNaN(window.adminId) ? true : false);
      setCanEdit(response.canEdit);
      setLoading(false);
    } else {
      setIsAuthorized(false);
      setAuthorizeMessage(result.data.message);
      console.log(window.sessionId);
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <StateProvider isSessionLong={isSessionLong}>
      {isAuthorized ? (
        <DndProvider backend={HTML5Backend}>
          <SimService
            languages={languages}
            teamNameValue={teamName}
            isSessionLong={isSessionLong}
            isAdmin={isAdmin}
            canEdit={canEdit}
          />
        </DndProvider>
      ) : (
        <div className="AuthorizationContainer">
          <div className="relativeContainer">
            {loading ? (
              <div className="NotAuthorizedContainerLoaderImg">
                <div className="loaderContainer">
                  <Image src={loaderImg} />
                </div>
              </div>
            ) : (
              <HomeComponent isLoggedOut={false} notAuthorizedMessage={authorizeMessage} />
            )}
          </div>
        </div>
      )}
    </StateProvider>
  );
}

export default App;
