import React from "react";
import { useDrag } from "react-dnd";
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

import Translate from "../../utils/Translate";

export default function DraggableToken({ canEdit, tokenType, value, containerId }) {
  const [, drag] = useDrag({
    item: {
      type: items.token[tokenType],
      value,
      containerId: containerId,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success && item.containerId !== containerId) {
          const obj = {
            tokenType,
            value,
          };
          RealtimeConnection.removeToken(obj);
        }
      }
    },
  });
  const canDrag = canEdit ? drag : null;

  return (
    <div className="tokenDraggable" ref={canDrag}>
      <div className="Token-container-draggable">
        <div className="Token-value">{value}</div>
        <div className="Token-text"> <Translate alias="Board" word={ `Credit ${tokenType}%` } /></div>
      </div>
    </div>
  );
}
