const initState = {
  incomeStatement: {
    revenues: 0,
    costOfSales: 0,

    personnelCostManager: 0,
    hq: 0,
    marketing: 0,
    indirectSalesCost: 0,
    hrDevelopmentCosts: 0,
    severanceCosts: 0,
    lease: 0,

    property: 0,
    equipment: 0,
    other: 0,

    interest: 0,

    tax: 0,

    dividends: 0,

    LCF: 0,
  },
  balanceSheet: {
    capitalizedDevelopmentCosts: 0,
    property: 0,
    equipment: 0,
    cash: 0,

    equity: 0,
    credit: 0,
  },
  cashFlow: {
    cashLevelAtStart: 0,

    revenues: 0,
    costOfSales: 0,
    personnelCostManager: 0,
    hq: 0,
    marketing: 0,
    indirectSalesCost: 0,
    hrDevelopmentCosts: 0,
    severanceCosts: 0,
    lease: 0,
    tax: 0,

    property: 0,
    equipment: 0,
    costs: 0,
    assets: 0,

    changeInCredit: 0,
    interest: 0,
    dividends: 0,
  },
};

export default initState;
