import React, { useState } from "react";
import { useDrag, DragPreviewImage, useDrop } from "react-dnd";
import DraggableCoin from "./DraggableCoin";
import { Modal } from "reactstrap";

// Constants
import items from "../../utils/items";

// Images
import previewImg from "../../assets/images/draggable-container-preview.png";
import horizontalContainerImg from "../../assets/images/container-h.png";
import verticalContainerImg from "../../assets/images/container-v.png";
import RealtimeConnection from "../../utils/RealtimeConnection";
import ContainerModal from "./ContainerModal";
import containerFlipped from "../../assets/images/_box-container-flipped.png";
import containerFlippedVertical from "../../assets/images/_box-container-v-flipped.png";

const DraggableContainer = ({
  containerItem,
  fieldIndex,
  alias,
  dispatch,
  branch,
  serviceIndex,
  regionIndex,
  isVertical,
  coinsCount,
  property,
  hub,
  canEdit,
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: items.container,
      index: fieldIndex,
      alias,
      branch,
      serviceIndex,
      regionIndex,
      coins: containerItem.coins,
      hub,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            alias,
            branch,
            index: fieldIndex,
            serviceIndex,
            regionIndex,
            hub,
          };
          RealtimeConnection.removeContainer(obj);
        }
      }
    },
  });

  const [isShown, setIsShown] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (containerItem.hasContainer) setModal(!modal);
  };
  const [{ containerId }, drop] = useDrop({
    accept: items.coin,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      containerId: monitor.getHandlerId(),
    }),
    drop: (item, monitor) => {
      if (monitor.getHandlerId() !== item.containerId) {
        if (containerItem.coins.length < 20) {
          if (item.isFromTeamTable) {
            if (coinsCount >= item.value) {
              const obj = {
                regionIndex,
                serviceIndex,
                fieldIndex,
                branch,
                alias,
                value: item.value,
                hub,
              };
              RealtimeConnection.addCoin(obj);
  
              return { success: true };
            }
          } else {
            const obj = {
              regionIndex,
              serviceIndex,
              fieldIndex,
              branch,
              alias,
              value: item.value,
              hub,
            };
            RealtimeConnection.addCoin(obj);
  
            return { success: true };
          }
        }
      }
    },
  });

  const coinsContainerClass = isVertical ? "Coins-vertical-droppable" : "Coins-droppable";
  const containerImg = isVertical ? verticalContainerImg : horizontalContainerImg;
  const flippedContainerImg = isVertical ? containerFlippedVertical : containerFlipped;
  const canDrag = canEdit ? drag : null;
  const canDrop = canEdit ? drop : null;
  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div className="containerObjClass" ref={canDrag}>
        <div
          className="coins-sum"
          style={
            isShown && containerItem.coins.reduce((a, b) => a + b, 0) > 0
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {containerItem.coins.reduce((a, b) => a + b, 0)}
        </div>
        <img
          src={containerItem.rented ? flippedContainerImg : containerImg}
          className="objModelClass"
          alt="container"
          width="100%"
          height="100%"
        />

        <div
          className={coinsContainerClass}
          ref={ (containerItem.rented) ? null : canDrop }
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          onClick={toggle}
        >
          {!isVertical && <span className="helper" />}
          {containerItem.coins.map((value, index) => (
            <DraggableCoin
              key={index}
              containerId={containerId}
              value={value}
              branch={branch}
              regionIndex={regionIndex}
              serviceIndex={serviceIndex}
              dispatch={dispatch}
              fieldIndex={fieldIndex}
              alias={alias}
              index={index}
              hub={hub}
              isLastCoin={
                containerItem.coins.length === index + 1 && (index + 1) % 5 === 0 ? true : false
              }
              canEdit={canEdit}
            />
          ))}
        </div>
        <Modal className="ContainerPropertiesPopup" isOpen={modal} toggle={toggle}>
          <ContainerModal
            coins={containerItem.coins}
            coinsCount={coinsCount}
            branch={branch}
            regionIndex={regionIndex}
            serviceIndex={serviceIndex}
            dispatch={dispatch}
            fieldIndex={fieldIndex}
            alias={alias}
            toggle={toggle}
            property={property}
            hub={hub}
            rented={containerItem.rented}
            canEdit={canEdit}
          />
        </Modal>
      </div>
    </>
  );
};

export default DraggableContainer;
