import React from "react";
import { useDrag } from "react-dnd";

// Images

import silverPreview from "../../assets/images/white-avatar-preview.png";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function SilverDraggableAvatar({
  canEdit,
  serviceIndex,
  regionIndex,
  index,
  alias,
}) {
  const [, drag] = useDrag({
    item: {
      type: "Silver",
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            serviceIndex,
            regionIndex,
            index,
            alias,
          };
          RealtimeConnection.removeAvatar(obj);
        }
      }
    },
  });

  const canDrag = canEdit ? drag : null;

  return (
    <div ref={canDrag}>
      <img src={silverPreview} alt="avatar" className="avatar-pic" />
    </div>
  );
}
