const strategyPaper = {
  first: [
    {
      name: "Develop Services",
      categories: [
        { name: "Service 2", subText: "($5 / $5)", value: false },
        { name: "Service 3", subText: "($5 / $5 / $5 / $5)", value: false },
      ],
      Liquidity: null,
    },
    {
      name: "Develop Regions",
      categories: [
        { name: "Region 2", subText: "($2 / $2)", value: false },
        { name: "Region 3", subText: "($2 / $2 / $2)", value: false },
      ],
      Liquidity: null,
    },
  ],
  second: [
    {
      name: "Purchase/Lease- Property (P) Purchase- Equipment (E) Service (S) Purchase Property ($5 / $5) Lease Property ($3 p.a.) Purchase Equipment ($5) Lease: $5/$2 p.a. Central/Low Cost",
      categories: [
        {
          name: "Region 1",
          subCategories: [
            {
              name: "S 2",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
            {
              name: "S 3",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
          ],
        },
        {
          name: "Region 2",
          subCategories: [
            {
              name: "S 1",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
            {
              name: "S 2",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
            {
              name: "S 3",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
          ],
        },
        {
          name: "Region 3",
          subCategories: [
            {
              name: "S 1",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
            {
              name: "S 2",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
            {
              name: "S 3",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
          ],
        },
        {
          name: "Service Hub - Only Lease of Property",
          subCategories: [
            {
              name: "Central",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
            {
              name: "Low Cost",
              fields: [
                { name: "P", value: false },
                { name: "E", value: false },
              ],
            },
          ],
        },
      ],
      Liquidity: null,
    },
  ],
  third: [
    {
      name: "Changes in Staff M = Management T = Team",
      categories: [
        {
          name: "Region 1",
          subCategories: [
            { name: "S1", fields: [{ name: "", value: null }, { name: "M (3$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (4$)" }] },
            { name: "S2", fields: [{ name: "", value: null }, { name: "M (3$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (3$)" }] },
            { name: "S3", fields: [{ name: "", value: null }, { name: "M (3$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (5$)" }] },
          ],
        },
      ],
      Liquidity: [
        { name: "Mgmt", value: null },
        { name: "Teams", value: null },
      ],
    },
    {
      name: "",
      categories: [
        {
          name: "Region 2",
          subCategories: [
            { name: "S1", fields: [{ name: "", value: null }, { name: "M (3$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (4$)" }] },
            { name: "S2", fields: [{ name: "", value: null }, { name: "M (3$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (3$)" }] },
            { name: "S3", fields: [{ name: "", value: null }, { name: "M (3$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (5$)" }] },
          ],
        },
      ],
      Liquidity: [{ name: "TotalSeverance (No Lay offs X $1)", value: null }],
    },
    {
      name: "",
      categories: [
        {
          name: "Region 3",
          subCategories: [
            { name: "S1", fields: [{ name: "", value: null }, { name: "M (2$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (3$)" }] },
            { name: "S2", fields: [{ name: "", value: null }, { name: "M (2$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (2$)" }] },
            { name: "S3", fields: [{ name: "", value: null }, { name: "M (2$)" }] },
            { name: "", fields: [{ name: "", value: null }, { name: "T (4$)" }] },
          ],
        },
      ],
      Liquidity: [{ name: "TotalHRDev (No empl. x $0.5)", value: null }],
    },
  ],
  fourth: {
    name: "Draw / Repay Credit",
    fields: [
      { name: "Outstanding Amount", value: null },
      { name: "Draw (+) Repay (-)", value: null },
      { name: "New Amount", value: null },
    ],
    Liquidity: [
      { name: "c. Marketing estimate", value: null },
      { name: "Overheads HQ", value: null },
      { name: "Total Liquidity needs (before revenues)", value: null },
    ],
  },
};

export default strategyPaper;
