import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import goldCoinImg from "../../assets/images/dollar_coin.png";
import silverCoinImg from "../../assets/images/dollar_coin_silver.png";
import bronzeCoinImg from "../../assets/images/dollar_coin_bronze.png";
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../assets/images/dollar_coin_bronze-preview.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableCoin({ canEdit, value }) {
  const [, drag, preview] = useDrag({
    item: {
      type: items.coin,
      value: value,
      isFromTeamTable: true,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            type: "coins",
            value: value,
          };
          RealtimeConnection.decreaseTeamTable(obj);
        }
      }
    },
  });

  let previewImg = previewGoldCoinImg;
  let coinImg = goldCoinImg;

  switch (value) {
    case 10:
      previewImg = previewGoldCoinImg;
      coinImg = goldCoinImg;
      break;
    case 1:
      previewImg = previewSilverCoinImg;
      coinImg = silverCoinImg;
      break;
    case 0.5:
      previewImg = previewBronzeCoinImg;
      coinImg = bronzeCoinImg;
      break;
    default:
      break;
  }

  const canDrag = canEdit ? drag : null;
  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div className="draggable-coins-container" ref={canDrag}>
        <img alt="draggable-coins" className="draggable-coins" src={coinImg} />
      </div>
    </>
  );
}
