import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import pin from "../../assets/images/pushpin2.png";
import pinPreview from "../../assets/images/pushpin-preview.png";

import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggablePin({ canEdit }) {
  const [, drag, preview] = useDrag({
    item: {
      type: items.pin,
      main: true,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            type: "pins",
            value: 1,
          };
          RealtimeConnection.decreaseTeamTable(obj);

          return { success: true };
        }
      }
    },
  });
  const canDrag = canEdit ? drag : null;

  return (
    <>
      <DragPreviewImage connect={preview} src={pinPreview} />
      <div className="draggable-coins-container" ref={canDrag}>
        <img alt="draggable-coins" className="draggable-coins" src={pin} />
      </div>
    </>
  );
}
