import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import yellow from "../../assets/images/avatar-2.png";
import yellowPreview from "../../assets/images/avatar-2-preview.png";

import blue from "../../assets/images/blue-avatar.png";
import bluePreview from "../../assets/images/blue-avatar-preview.png";

import green from "../../assets/images/avatar.png";
import greenPreview from "../../assets/images/avatar-preview.png";

import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableAvatar({ canEdit, serviceIndex, count }) {
  const [, drag, preview] = useDrag({
    item: {
      type: items.avatar[serviceIndex],
      count,
      main: true,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          let avatarType = "";
          switch (serviceIndex) {
            case 0:
              avatarType = "goldAvatar";
              break;
            case 1:
              avatarType = "greenAvatar";
              break;
            case 2:
              avatarType = "blueAvatar";
              break;
            default:
              break;
          }
          if (avatarType !== "") {
            const obj = {
              type: avatarType,
            };
            RealtimeConnection.decreaseTeamTable(obj);
          }
        }
      }
    },
  });
  let Img = blue;
  let previewImg = blue;

  switch (serviceIndex) {
    case 0:
      Img = yellow;
      previewImg = yellowPreview;
      break;
    case 1:
      Img = green;
      previewImg = greenPreview;
      break;
    case 2:
      Img = blue;
      previewImg = bluePreview;
      break;
    default:
      break;
  }
  const canDrag = canEdit ? drag : null;

  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div className="draggable-coins-container" ref={canDrag}>
        <img alt="draggable-coins" className="draggable-coins" src={Img} />
      </div>
    </>
  );
}
