import React from "react";
import { useDrop } from "react-dnd";
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";
import DraggableAvatar from "./DraggableAvatar";

export default function AvatarContainer({
  dispatch,
  serviceIndex,
  regionIndex,
  index,
  checked,
  alias,
  canEdit,
}) {
  const [{ isOver }, drop] = useDrop({
    accept: items.avatar[serviceIndex],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      const obj = {
        serviceIndex,
        regionIndex,
        index,
        alias,
      };
      if (item.count <= 0) {
        return {};
      } else {
        RealtimeConnection.addAvatar(obj);
        return { success: true };
      }
    },
  });

  const canDrop = canEdit ? drop : null;
  return (
    <React.Fragment>
      {
        (checked) ?
          <div className="employee-circle">
            <DraggableAvatar
              index={index}
              serviceIndex={serviceIndex}
              dispatch={dispatch}
              alias={alias}
              regionIndex={regionIndex}
              canEdit={canEdit}
            />
          </div>
          :
        <div className="employee-circle" ref={canDrop}></div>
      }
    </React.Fragment>
  );
}
