import React from "react";
import { Table, FormControl } from "react-bootstrap";
import InputNumber from "rc-input-number";
import RealtimeConnection from "../../../utils/RealtimeConnection";

import Translate from "../../../utils/Translate";

class BalanceSheet extends React.Component {
  handleChange(field, value) {
    if (this.props.canEdit) {
      RealtimeConnection.changeAnnualReport({
        year: this.props.year,
        groupName: "balanceSheet",
        field,
        value,
      });
    }
  }

  render() {
    let totalAssets = 0;
    totalAssets += !isNaN(parseFloat(this.props.balanceSheet.capitalizedDevelopmentCosts))
      ? parseFloat(this.props.balanceSheet.capitalizedDevelopmentCosts)
      : 0;
    totalAssets += !isNaN(parseFloat(this.props.balanceSheet.property))
      ? parseFloat(this.props.balanceSheet.property)
      : 0;
    totalAssets += !isNaN(parseFloat(this.props.balanceSheet.equipment))
      ? parseFloat(this.props.balanceSheet.equipment)
      : 0;
    totalAssets += !isNaN(parseFloat(this.props.balanceSheet.cash))
      ? parseFloat(this.props.balanceSheet.cash)
      : 0;

    let totalLiabilities = 0;
    totalLiabilities += !isNaN(parseFloat(this.props.balanceSheet.equity))
      ? parseFloat(this.props.balanceSheet.equity)
      : 0;

    totalLiabilities += !isNaN(parseFloat(this.props.balanceSheet.credit))
      ? parseFloat(this.props.balanceSheet.credit)
      : 0;

    return (
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="2">
              <div className="bold"><Translate alias="Forms" word="Assets" /></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Capitalized Development Costs" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.balanceSheet.capitalizedDevelopmentCosts}
                onChange={(value) => this.handleChange("capitalizedDevelopmentCosts", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Property" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.balanceSheet.property}
                onChange={(value) => this.handleChange("property", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Equipment" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.balanceSheet.equipment}
                onChange={(value) => this.handleChange("equipment", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Cash" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.balanceSheet.cash}
                onChange={(value) => this.handleChange("cash", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Total assets" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={totalAssets} disabled />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Equity" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.balanceSheet.equity}
                onChange={(value) => this.handleChange("equity", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div className="bold"><Translate alias="Forms" word="Credit" /></div>
            </td>
            <td>
              <InputNumber
                min="0"
                className="annualField"
                value={this.props.balanceSheet.credit}
                onChange={(value) => this.handleChange("credit", value)}
                disabled={!this.props.canEdit}
              />
            </td>
          </tr>
          <tr className="autofillFieldBackground">
            <td>
              <div className="bold"><Translate alias="Forms" word="Total equity and liabilities" /></div>
            </td>
            <td>
              <FormControl aria-label="Small" size="sm" value={totalLiabilities} disabled />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default BalanceSheet;
