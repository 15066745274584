import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../assets/images/dollar_coin_bronze-preview.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function DraggableCoin({
  canEdit,
  value,
  isLastCoin,
  fieldIndex,
  alias,
  index,
  dispatch,
  regionIndex,
  serviceIndex,
  branch,
  hub,
  containerId
}) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: items.coin,
      value,
      fieldIndex,
      alias,
      index,
      dispatch,
      regionIndex,
      serviceIndex,
      branch,
      hub,
      containerId
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            regionIndex,
            serviceIndex,
            fieldIndex,
            alias,
            index,
            value,
            branch,
            hub,
          };
          RealtimeConnection.removeCoin(obj);
        }
      }
    },
  });

  let canPlayerDrag = canEdit ? drag : null;

  let previewImg = previewGoldCoinImg;
  let coinClass = isLastCoin ? "last-coin " : "not-last-coin ";
  switch (value) {
    case 10:
      coinClass += "gold";
      previewImg = previewGoldCoinImg;
      break;
    case 1:
      coinClass += "silver";
      previewImg = previewSilverCoinImg;
      break;
    case 0.5:
      coinClass += "bronze";
      previewImg = previewBronzeCoinImg;
      break;
    default:
      break;
  }

  // const style = {
  // 	opacity: (isDragging || (isObjectDragging && draggingObject === "COIN" && index === dragCoinIndex)) ? 0.5 : 1
  // }
  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div className={["coin", coinClass].join(" ")} ref={canPlayerDrag} />
    </>
  );
}
