import React, { createContext, useReducer } from "react";
import { initialBoardState } from "../utils/init-state";
import { initialTeamTableState } from "../utils/init-team-table-state";
import sessionState from "../utils/session-init-state";
import DecisionSheet from "../utils/decision-sheet";
import StrategyPaper from "../utils/strategy-paper";
import AnnualReport from "../utils/annual-report-init-state";
import produce from "immer";

const initialState = {
  Board: initialBoardState,
  TeamTable: initialTeamTableState,
  Session: sessionState,
  strategyPaper: StrategyPaper,
  decisionSheet: DecisionSheet,
  annualReport: AnnualReport,
};

export const StateContext = createContext();
export const DispatchContext = createContext();

const refactorCoins = (coins) => {
  let newCoins = [];

  if (coins.length > 0) {
    let coinsValue = coins.reduce((total, num) => {
      return parseFloat(total) + parseFloat(num);
    });

    while (coinsValue > 0) {
      if (coinsValue >= 10) {
        newCoins.push(10);
        coinsValue -= 10;
      } else if (coinsValue >= 1) {
        newCoins.push(1);
        coinsValue -= 1;
      } else {
        newCoins.push(0.5);
        coinsValue -= 0.5;
      }
    }
  }

  return newCoins;
};

function refactorSum(coins) {
  var coinsList = [];
  while (coins > 0) {
    if (coins >= 10) {
      coinsList.push(10);
      coins -= 10;
    } else if (coins >= 1) {
      coinsList.push(1);
      coins -= 1;
    } else {
      coinsList.push(0.5);
      coins -= 0.5;
    }
  }
  return coinsList;
}
function reducer(draft, action) {
  switch (action.type) {
    case "RestoreTeamTable":
      draft.TeamTable = action.data;
      return;
    case "RestoreBoard":
      draft.Board = action.data;
      return;
    case "RestoreMsgs":
      draft.Session.messages = action.data;
      return;
    case "RestoreForm":
      if (action.data) {
        if (action.data.decisionSheet) {
          draft.decisionSheet = action.data.decisionSheet.decisionSheet;
        }
        if (action.data.annualReport) {
          draft.annualReport = action.data.annualReport.annualReport;
        }
        if (action.data.strategyPaper) {
          draft.strategyPaper = action.data.strategyPaper.strategyPaper;
        }
      }
      return;
    case "changeFormYear":
      if (action.data.form) {
        draft[action.data.type] = action.data.form[action.data.type];
      } else {
        draft.decisionSheet = JSON.parse(JSON.stringify(DecisionSheet));
        draft.annualReport = JSON.parse(JSON.stringify(AnnualReport));
      }
      draft.Session.formSelectedYear = action.data.year;

      return;
    case "changeYear":
      draft.Session.formSelectedYear = action.year;
      draft.Session.year = action.year;

      return;
    case "RestoreLaborMarket":
      draft.LaborMarket = action.data;
      return;
    case "LaborMarket":
      draft.LaborMarket[action.data.region][action.data.key] = action.data.value;
      return;
    case "UpdateTeamTable":
      draft.TeamTable[action.data.type] = action.data.value;
      return;
    case "UpdateRegionCustomerData":
      draft.Board.regions[action.data.region].customer[action.data.key] = action.data.value;
      return;
    case "UpdateBoardValue":
      console.log(action.type);
      draft.Board.rightBlock[action.data.type] = action.data.value;
      return;
    case "DecreaseCash":
      let containers = draft.Board.cash.map((x) => {
        let cashCoins = 0;
        cashCoins = x.coins.reduce((a, b) => a + b, 0);
        return cashCoins;
      });

      let index = 0;
      let count = action.data.value;
      while (count > 0) {
        if (containers[index] >= count) {
          let coinsList = draft.Board.cash[index].coins;
          coinsList = refactorSum(containers[index] - count);
          draft.Board.cash[index].coins = coinsList;
          count = 0;
        } else {
          count -= containers[index];
          draft.Board.cash[index].coins = [];
        }
        index++;
      }

      return;
    case "IncreaseCash":
      let containerIndex = 0;
      let valueCount = action.data.value;
      let firstContainer = draft.Board.cash[containerIndex];
      let coinsList = draft.Board.cash[containerIndex].coins;

      if (firstContainer.hasContainer) {
        var coins = firstContainer.coins.reduce((a, b) => a + b, 0) + action.data.value;
        coinsList = refactorSum(coins);
        while (valueCount > 0) {
          if (coinsList.length < 20) {
            draft.Board.cash[containerIndex].coins = coinsList;
            valueCount = 0;
          }
          containerIndex++;
        }
      }

      return;
    case "updateRightBlock":
      console.log("here");
      draft.Board.rightBlock.depreciation.map((item, itemIndex) => {
        if (item.coins.length > 0) {
          item.coins = [];
          item.hasContainer = false;
        }
      });
      draft.Board.rightBlock.costOfSales.map((item, itemIndex) => {
        if (item.coins.length > 0) {
          item.coins = [];
          item.hasContainer = false;
        }
      });
      return;
    case "DecreaseTeamTable":
      console.log(action.data.value);
      draft.TeamTable[action.data.type] -= action.data.value;
      return;
    case "IncreaseTeamTable":
      console.log(action.data);
      draft.TeamTable[action.data.type] += action.data.value;
      if (action.data.type === "token5") {
        draft.Board.leftBlock.credit5.value = 0;
      }
      if (action.data.type === "token10") {
        draft.Board.leftBlock.credit10.value = 0;
      }
      return;
    case "toggleChat":
      draft.Session.isChatOpened = action.isOpened;
      return;
    case "messagesList":
      var message = action.message;
      draft.Session.messages.push(message);
      return;
    case "addEquity":
      draft.Board.leftBlock.equity = action.value;
      return;
    case "decisionSheet":
      draft.Session["formStatus"].decisionSheet = action.value;
      return;
    case "updateDecisionSheet":
      draft.decisionSheet[action.data.regionIndex].services[action.data.serviceIndex][
        action.data.itemIndex
      ].value = action.data.value;

      return;
    case "strategyPaper":
      draft.Session["formStatus"].strategyPaper = action.value;
      return;
    case "updateStrategyPaper":
      if (action.data.alias === "fourth") {
        if (action.data.liquidityIndex !== undefined) {
          draft.strategyPaper[action.data.alias].Liquidity[action.data.liquidityIndex].value =
            action.data.value;
        } else {
          draft.strategyPaper[action.data.alias].fields[action.data.fieldIndex].value =
            action.data.value;
        }
      }
      if (
        action.data.categoryIndex === undefined &&
        action.data.subCategoryIndex === undefined &&
        action.data.alias !== "third" &&
        action.data.alias !== "fourth"
      ) {
        draft.strategyPaper[action.data.alias][action.data.companyIndex].Liquidity =
          action.data.value;
      }
      if (
        action.data.categoryIndex !== undefined &&
        action.data.subCategoryIndex === undefined &&
        action.data.fieldIndex === undefined
      ) {
        draft.strategyPaper[action.data.alias][action.data.companyIndex].categories[
          action.data.categoryIndex
        ].value = action.data.value;
      }
      if (
        action.data.fieldIndex !== undefined &&
        action.data.liquidityIndex === undefined &&
        action.data.alias !== "fourth"
      ) {
        draft.strategyPaper[action.data.alias][action.data.companyIndex].categories[
          action.data.categoryIndex
        ].subCategories[action.data.subCategoryIndex].fields[action.data.fieldIndex].value =
          action.data.value;
      }
      if (
        action.data.fieldIndex === undefined &&
        action.data.categoryIndex === undefined &&
        action.data.liquidityIndex !== undefined &&
        action.data.alias !== "fourth"
      ) {
        draft.strategyPaper[action.data.alias][action.data.companyIndex].Liquidity[
          action.data.liquidityIndex
        ].value = action.data.value;
      }

      return;
    case "annualReport":
      draft.Session["formStatus"].annualReport = action.value;
      return;
    case "updateAnnualReport":
      draft.annualReport[action.data.groupName][action.data.field] = action.data.value;
      return;
    case "addContainer":
      if (action.index !== undefined) {
        if (action.serviceIndex !== undefined) {
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.index
          ].hasContainer = true;
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.index
          ].coins = action.coins;
        } else {
          if (action.hub) {
            draft.Board[action.branch][action.alias].containers[action.index].hasContainer = true;
            draft.Board[action.branch][action.alias].containers[action.index].coins = action.coins;
          } else {
            draft.Board[action.branch][action.alias][action.index].hasContainer = true;
            draft.Board[action.branch][action.alias][action.index].coins = action.coins;
          }
        }
      } else {
        draft.Board[action.branch][action.alias].hasContainer = true;
        draft.Board[action.branch][action.alias].coins = action.coins;
      }

      return;
    case "removeContainer":
      if (action.index !== undefined) {
        if (action.serviceIndex !== undefined) {
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.index
          ].hasContainer = false;
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.index
          ].coins = [];
        } else {
          if (action.hub) {
            draft.Board[action.branch][action.alias].containers[action.index].hasContainer = false;
            draft.Board[action.branch][action.alias].containers[action.index].coins = [];
          } else {
            draft.Board[action.branch][action.alias][action.index].hasContainer = false;
            draft.Board[action.branch][action.alias][action.index].coins = [];
          }
        }
      } else {
        draft.Board[action.branch][action.alias].hasContainer = false;
        draft.Board[action.branch][action.alias].coins = [];
      }
      return;
    case "deleteContainer":
      let sum = action.coins.reduce((a, b) => a + b, 0);
      draft.TeamTable.coins += sum;
      return;
    case "addCoin":
      if (action.fieldIndex !== undefined) {
        if (action.serviceIndex !== undefined) {
          let coinList = [
            ...draft.Board.regions[action.regionIndex].services[action.serviceIndex][
              action.fieldIndex
            ].coins,
          ];
          coinList.push(action.value);
          coinList = refactorCoins(coinList);
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.fieldIndex
          ].coins = coinList;
        } else {
          if (action.hub) {
            let coinList = [
              ...draft.Board[action.branch][action.alias].containers[action.fieldIndex].coins,
            ];
            coinList.push(action.value);
            coinList = refactorCoins(coinList);
            draft.Board[action.branch][action.alias].containers[action.fieldIndex].coins = coinList;
          } else {
            let coinList = [...draft.Board[action.branch][action.alias][action.fieldIndex].coins];
            coinList.push(action.value);
            coinList = refactorCoins(coinList);
            draft.Board[action.branch][action.alias][action.fieldIndex].coins = coinList;
          }
        }
      } else {
        let coinList = [...draft.Board[action.branch][action.alias].coins];
        coinList.push(action.value);
        coinList = refactorCoins(coinList);
        draft.Board[action.branch][action.alias].coins = coinList;
      }

      return;
    case "removeCoin":
      if (action.fieldIndex !== undefined) {
        if (action.serviceIndex !== undefined) {
          let coinList = [
            ...draft.Board.regions[action.regionIndex].services[action.serviceIndex][
              action.fieldIndex
            ].coins,
          ];
          coinList.splice(action.index, 1);
          coinList = refactorCoins(coinList);
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.fieldIndex
          ].coins = coinList;
        } else {
          if (action.hub) {
            let coinList = [
              ...draft.Board[action.branch][action.alias].containers[action.fieldIndex].coins,
            ];
            coinList.splice(action.index, 1);
            coinList = refactorCoins(coinList);
            draft.Board[action.branch][action.alias].containers[action.fieldIndex].coins = coinList;
          } else {
            let coinList = [...draft.Board[action.branch][action.alias][action.fieldIndex].coins];
            coinList.splice(action.index, 1);
            coinList = refactorCoins(coinList);
            draft.Board[action.branch][action.alias][action.fieldIndex].coins = coinList;
          }
        }
      } else {
        let coinList = [...draft.Board[action.branch][action.alias].coins];
        coinList.splice(action.index, 1);
        coinList = refactorCoins(coinList);
        draft.Board[action.branch][action.alias].coins = coinList;
      }

      return;
    case "addToken":
      if (action.tokenType === 5) {
        draft.Board.leftBlock.credit5.checked = true;
        draft.Board.leftBlock.credit5.value = action.value;
      } else {
        draft.Board.leftBlock.credit10.checked = true;
        draft.Board.leftBlock.credit10.value = action.value;
      }
      return;
    case "deleteToken":
      if (action.tokenType === 5) {
        draft.Board.leftBlock.credit5.checked = false;
      } else {
        draft.Board.leftBlock.credit10.checked = false;
      }
      return;

    case "addAvatar":
      draft.Board.regions[action.regionIndex].employees[action.serviceIndex][action.alias][
        action.index
      ].checked = true;
      return;
    case "addPin":
      if (action.regionIndex !== undefined) {
        draft.Board.regions[action.regionIndex].pins[action.index].checked = true;
      }
      if (action.serviceIndex !== undefined) {
        draft.Board.leftBlock.services[action.serviceIndex].pins[action.index].checked = true;
      }
      if (action.alias !== undefined) {
        draft.Board.leftBlock[action.alias].pins[action.index].checked = true;
      }
      return;

    case "deletePin":
      if (action.regionIndex !== undefined) {
        draft.Board.regions[action.regionIndex].pins[action.index].checked = false;
      }
      if (action.serviceIndex !== undefined) {
        draft.Board.leftBlock.services[action.serviceIndex].pins[action.index].checked = false;
      }
      if (action.alias !== undefined) {
        draft.Board.leftBlock[action.alias].pins[action.index].checked = false;
      }
      return;
    case "deleteAvatar":
      draft.Board.regions[action.regionIndex].employees[action.serviceIndex][action.alias][
        action.index
      ].checked = false;
      return;

    case "addFiles":
      draft.Session.files.push(action.files);
      return;
    case "removeFiles":
      let list = draft.Session.files.filter((file) => file._id !== action.files._id);
      draft.Session.files = list;
      return;
    case "files":
      draft.Session.files = action.files;
      return;

    case "locationStatus":
      if (action.index !== undefined) {
        if (action.serviceIndex !== undefined) {
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.index
          ].rented = action.value;
          draft.Board.regions[action.regionIndex].services[action.serviceIndex][
            action.index
          ].coins = action.coins;
        } else {
          if (action.hub) {
            draft.Board[action.branch][action.alias].containers[action.index].rented = action.value;
            draft.Board[action.branch][action.alias].containers[action.index].coins = action.coins;
          } else {
            draft.Board[action.branch][action.alias][action.index].rented = action.value;
            draft.Board[action.branch][action.alias][action.index].coins = action.coins;
          }
        }
      } else {
        draft.Board[action.branch][action.alias].rented = action.value;
        draft.Board[action.branch][action.alias].coins = action.coins;
      }

      return;
    case "containerDragging":
      if (action.regionIndex !== undefined) {
        draft.Board.regions[action.regionIndex][action.alias][action.id].isObjectDragging =
          action.isDragging ? true : false;
        draft.Board.regions[action.regionIndex][action.alias][action.id].draggingObject =
          "CONTAINER";
        draft.Board.regions[action.regionIndex][action.alias][action.id].playerId =
          action.isDragging ? action.playerId : null;
      } else {
        draft.Board[action.alias][action.id].isObjectDragging = action.isDragging ? true : false;
        draft.Board[action.alias][action.id].draggingObject = "CONTAINER";
        draft.Board[action.alias][action.id].playerId = action.isDragging ? action.playerId : null;
      }
      return;
    case "coinDragging":
      if (action.regionIndex !== undefined) {
        draft.Board.regions[action.regionIndex][action.alias][action.id].isObjectDragging =
          action.isDragging ? true : false;
        draft.Board.regions[action.regionIndex][action.alias][action.id].draggingObject = "COIN";
        draft.Board.regions[action.regionIndex][action.alias][action.id].playerId =
          action.isDragging ? action.playerId : null;
        draft.Board.regions[action.regionIndex][action.alias][action.id].coinIndex =
          action.coinIndex;
      } else {
        draft.Board[action.alias][action.id].isObjectDragging = action.isDragging ? true : false;
        draft.Board[action.alias][action.id].draggingObject = "COIN";
        draft.Board[action.alias][action.id].playerId = action.isDragging ? action.playerId : null;
        draft.Board[action.alias][action.id].coinIndex = action.coinIndex;
      }
      return;
    default:
      return;
  }
}

const curriedReducer = produce(reducer);
export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(curriedReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};
