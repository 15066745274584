import React, { Component } from "react";

import Linkify from "react-linkify";

// CSS
import "../../assets/css/Chat.css";

import RealtimeConnection from "../../utils/RealtimeConnection";

class ChatComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    const scrollHeight = this.messagesEnd.scrollHeight;
    const height = this.messagesEnd.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  sendMessage = () => {
    if (this.state.message !== "") {
      // Update Socket
      RealtimeConnection.sendMessage({
        message: this.state.message,
      });

      this.setState({ message: "" }, () => {
        this.resizeFrame();
      });
    }
  };

  resizeFrame = (e) => {
    let msg = document.getElementById("messageTxtArea");
    let div = document.getElementsByClassName("form-container")[0];

    setTimeout(function () {
      msg.style.cssText = "height:0px";
      let height = Math.min(20 * 5, msg.scrollHeight);
      if (height < 30) {
        height = 30;
      }
      div.style.cssText = "height:" + (height + 10) + "px";
      msg.style.cssText = "height:" + height + "px";
    }, 0);
  };

  render() {
    return (
      <div className="chat-container">
        <div className="chat-popup" id="myForm">
          <div className="chat-popup-header">
            <div className="chat-popup-header-title">Messages</div>
            <div className="chat-popup-header-btn">
              <button type="button" onClick={this.props.closeChat}>
                X
              </button>
            </div>
          </div>
          <div id="MessagesList" className="chat-messages" ref={(el) => (this.messagesEnd = el)}>
            {this.props.session.messages
              ? this.props.session.messages.map((obj, index) => {
                  return (
                    <div key={index} className="w-100 d-inline-block">
                      <div
                        className="chat-message"
                        style={
                          window.playerId === obj.playerId
                            ? {
                                float: "right",
                                background: "rgb(27, 91, 151,0.2)",
                              }
                            : { float: "left" }
                        }
                      >
                        <span
                          className="playerName"
                          style={
                            window.playerId === obj.playerId
                              ? {
                                  textAlign: "right",
                                }
                              : {
                                  textAlign: "left",
                                }
                          }
                        >
                          {obj.playerName}
                        </span>
                        <div className="message">
                          <Linkify
                            properties={{
                              target: "_blank",
                            }}
                          >
                            {obj.text}
                          </Linkify>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="chat-form">
            <div className="form-container">
              <textarea
                id="messageTxtArea"
                placeholder="Type message.."
                name="msg"
                rows="1"
                required
                value={this.state.message}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    this.sendMessage();
                    event.preventDefault();
                    event.stopPropagation();
                  } else {
                    this.resizeFrame();
                  }
                  return true;
                }}
                onChange={(event) => {
                  this.setState({
                    message: event.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatComponent;
