import React from "react";
import { useDrag } from "react-dnd";
import items from "../../utils/items";

// Images
import pin from "../../assets/images/pushpin2.png";
import pinPreview from "../../assets/images/pushpin-preview.png";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function DraggablePin({ canEdit, regionIndex, index, serviceIndex, alias }) {
  const [, drag] = useDrag({
    item: {
      type: items.pin,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            regionIndex,
            index,
            serviceIndex,
            alias,
          };
          RealtimeConnection.removePin(obj);
        }
      }
    },
  });

  const canDrag = canEdit ? drag : null;
  return (
    <div ref={canDrag}>
      <img src={pin} alt="pin" className="pin-pic" />
    </div>
  );
}
