import React from "react";
import { useDrag } from "react-dnd";
import items from "../../utils/items";

// Images
import yellow from "../../assets/images/avatar-2.png";
import yellowPreview from "../../assets/images/avatar-2-preview.png";

import blue from "../../assets/images/blue-avatar.png";
import bluePreview from "../../assets/images/blue-avatar-preview.png";

import green from "../../assets/images/avatar.png";
import greenPreview from "../../assets/images/avatar-preview.png";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function DraggableAvatar({ canEdit, serviceIndex, regionIndex, index, alias }) {
  const [, drag] = useDrag({
    item: {
      type: items.avatar[serviceIndex],
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            serviceIndex,
            regionIndex,
            index,
            alias,
          };
          RealtimeConnection.removeAvatar(obj);
        }
      }
    },
  });
  let Img = blue;
  let previewImg = blue;
  switch (serviceIndex) {
    case 0:
      Img = yellow;
      previewImg = yellowPreview;
      break;
    case 1:
      Img = green;
      previewImg = greenPreview;
      break;
    case 2:
      Img = blue;
      previewImg = bluePreview;
      break;
    default:
      break;
  }
  const canDrag = canEdit ? drag : null;
  return (
    <div ref={canDrag}>
      <img src={previewImg} alt="avatar" className="avatar-pic" />
    </div>
  );
}
