const decisionSheet = [
  {
    id: 1,
    services: [
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
    ],
  },
  {
    id: 2,
    services: [
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
    ],
  },
  {
    id: 3,
    services: [
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
      [
        { name: "Units", value: 0 },
        { name: "Price", value: 0 },
        { name: "Marketing", value: 0 },
      ],
    ],
  },
];

export default decisionSheet;
