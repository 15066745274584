export const initialTeamTableState = {
  coins: 0,
  token5: 0,
  token10: 0,
  pins: 0,
  // reputation: 0,
  // standardLocation: 1,
  // premiumLocation: 0,
  // standardEquipment: 1,
  // premiumEquipment: 0,
  goldAvatar: 0,
  blueAvatar: 0,
  silverAvatar: 0,
  greenAvatar: 0,
};
