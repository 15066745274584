import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

// Utils
import URLs from "../../utils/urls";
import RealtimeConnection from "../../utils/RealtimeConnection";

import Translate from "../../utils/Translate";

// Images
import logo from "../../assets/images/simdustry-logo.png";
import zoomIn from "../../assets/images/zoom-in.png";
import zoomOut from "../../assets/images/zoom-out.png";
import chatIcon from "../../assets/images/chat-icon.png";
import logoutIcon from "../../assets/images/logout-icon.png";
import ChatComponent from "./ChatComponent";

let counterTimer = undefined;
let myInterval = undefined;

export default function FixedHeader({
	counter,
	teamName,
	session,
	dispatch,
	setZoom,
	zoomValue,
	canEdit,
	languages
}) {
	const [counterName, setCounterName] = useState("");
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [currentTeamName, setCurrentTeamName] = useState("");
	const [editTeamName, setEditTeamName] = useState(false);
	const [modifiedTeamName, setModifiedTeamName] = useState("");
	const [currentLang, setCurrentLang] = useState("");

	const [modalChat, setModalChat] = useState(false);
	const toggleChat = () => {
		setModalChat(!modalChat, () => {
			dispatch({ type: "toggleChat", isOpened: !modalChat });
		});
	};

	useEffect(() => {
		setCurrentTeamName(teamName);
		setModifiedTeamName(teamName);
	}, [teamName]);

	useEffect(() => {
		const lang = languages.find((x) => x.id == localStorage.langId);
		if (lang) {
			setCurrentLang(lang.name);
		} else {
			localStorage.langId = languages[0].id;
			setCurrentLang(languages[0].name);
		}
	}, []);

	/*useEffect(() => {

		//let audioElement = document.getElementById("myAudio");
		if (minutes === 0 && seconds === 0 && myInterval) { //  && el
			console.log("HEREEEE")
			clearInterval(myInterval);
		}

		//fetchCounter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session.year, minutes, seconds]);*/

	useEffect(() => {
		if (myInterval) {
			clearInterval(myInterval);
		}

		fetchCounter();
		/*if (counter) {
			fetchCounter();
		} else {
			//setCounterName("");
			setMinutes(0);
		}*/
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter]);

	const fetchCounter = async () => {
		const result = await axios.post(URLs.activeCounter, {
			id: window.sessionId,
		});

		const response = result.data;
		if (response.data) {
			counterTimer = response.data;
			checkCounter();
		}
	};

	const checkCounter = () => {
		const currentDate = moment().utc();
		const counterDate = moment(counterTimer.createdAt).utc();
		const endDate = counterDate.clone().add(parseInt(counterTimer.duration), "minutes");

		const minutesDiff = endDate.diff(currentDate);
		if (minutesDiff > 0) {
			setCounterName(counterTimer.name);
			if (Math.ceil((minutesDiff / 1000) % 60) === 60) {
				setMinutes(Math.ceil(minutesDiff / 1000 / 60));
				setSeconds(0);
			} else {
				if (minutesDiff > 60000) {
					setMinutes(Math.ceil(minutesDiff / 1000 / 60) - 1);
				} else {
					setMinutes(0);
				}
				setSeconds(Math.ceil((minutesDiff / 1000) % 60));
			}

			myInterval = setInterval(() => {
				calculateCounter();
			}, 1000);
		} else {
			setCounterName(counterTimer.name);
			setMinutes(0);
			setSeconds(0);
			clearInterval(myInterval);
		}
	};

	const calculateCounter = () => {
		const currentDate = moment().utc();
		const counterDate = moment(counterTimer.createdAt).utc();
		const endDate = counterDate.clone().add(parseInt(counterTimer.duration), "minutes");

		const minutesDiff = endDate.diff(currentDate);
		if (minutesDiff > 0) {
			setCounterName(counterTimer.name);
			if (Math.ceil((minutesDiff / 1000) % 60) === 60) {
				setMinutes(Math.ceil(minutesDiff / 1000 / 60));
				setSeconds(0);
			} else {
				if (minutesDiff > 60000) {
					setMinutes(Math.ceil(minutesDiff / 1000 / 60) - 1);
				} else {
					setMinutes(0);
				}
				setSeconds(Math.ceil((minutesDiff / 1000) % 60));
			}
		} else {
			setCounterName(counterTimer.name);
			setMinutes(0);
			setSeconds(0);
			clearInterval(myInterval);
		}
	};

	const updateTeamName = () => {
		const dataObj = {
			playerId: window.playerId,
			teamId: window.teamId,
			token: window.token,
			name: modifiedTeamName,
		};
		changeTeamName(dataObj);

		// Update Socket
		RealtimeConnection.changeTeamName({
			value: dataObj.name,
		});
	};

	const changeTeamName = async (data) => {
		setEditTeamName(false);

		const result = await axios.post(URLs.renameTeam, data);
		console.log(result.data);
	};

	const onChangeLang = (lang, isRTL) => {
		if (localStorage.langId !== lang) {
			localStorage.langId = lang;
			localStorage.isRTL = isRTL ? 1 : 0;

			window.location.reload();
		}
	};

	return (
		<>
			<div className="App-header">
				<div className="App-header-container">
					<div className="viewOnlyContainer" style={{ width: "10%" }}>
						{!canEdit && <span>View Only</span>}
					</div>
					{/*<div style={{ width: "15%", margin: "auto 0", height: "36px" }}>
						<div className="undo-btn">Undo Last Move</div>
					</div>*/}
					<div className="header-logo-container p-1">
						<img src={logo} className="App-logo" alt="App-logo" />
					</div>
					<div className="board-name">
						SIM <span>Service</span>{" "}
					</div>
					<div className="change-team-name-container">
						{editTeamName && canEdit ? (
							<Container className="bg-blue form">
								<div className="team-data-content">
									<Row>
										<Col className="team-form-container">
											<input
												type="text"
												value={modifiedTeamName}
												onChange={(e) => setModifiedTeamName(e.target.value)}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="team-form-container">
											<div className="team-name-btn" onClick={() => updateTeamName()}>
												<Translate alias="Board" word="Save" />
											</div>
										</Col>
									</Row>
								</div>
							</Container>
						) : (
							<Container className="bg-blue noPaddingLeftRight">
								<div className="widget-content">
									<div className="header-section"><Translate alias="Board" word="Team" /></div>
									<div className="team-data-content">
										<Row className="cursorPointer" onClick={() => setEditTeamName(true)}>
											<Col>
												<div className="team-name-text">{currentTeamName}</div>
											</Col>
										</Row>
									</div>
								</div>
							</Container>
						)}
					</div>
					<div className="player-name-container">
						<Container className="bg-green noPaddingLeftRight">
							<div className="widget-content">
								<div className="header-section"><Translate alias="Board" word="Player" /></div>
								<div className="team-data-content">
									<Row>
										<Col>
											<div className="player-name">{window.playerName}</div>
										</Col>
									</Row>
								</div>
							</div>
						</Container>
					</div>
					<div className="year-container">
						<Container className="bg-blue">
							<div className="widget-content">
								<div className="team-data-content">
									<Row>
										<Col style={{ display: "flex" }}>
											<div
												style={{
													display: "flex",
													margin: "0 auto",
												}}
											>
												<div className="header-section"><Translate alias="Board" word="Year" /></div>
												<div className="year-value">{session.year}</div>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Container>
					</div>

					<div className="counter-container">
						{counterName && (
							<Container className="bg-red">
								<div className="widget-content">
									<div className="header-section">{counterName}</div>
									<div className="team-data-content">
										<Row>
											<Col>
												<div
													className="counter-value"
													style={{
														color: minutes === 0 && seconds > 0 ? "#FF0000" : "#0A2955",
													}}
												>
													{minutes > 9 ? minutes : `0${minutes}`} :{" "}
													{seconds > 9 ? seconds : `0${seconds}`}
												</div>
											</Col>
										</Row>
									</div>
								</div>
								<div className="header-separator-2">&nbsp;</div>
							</Container>
						)}
					</div>

					<div className="zoom-container">
						<Container>
							<Row>
								<Col>
									<div className="zoom-btn-container">
										<img
											src={zoomIn}
											className="zoom-btn"
											alt="zoom-in"
											onClick={() => zoomValue < 150 && setZoom(zoomValue + 10)}
										/>
									</div>
								</Col>
								<Col>
									<div className="zoom-btn-container">
										<img
											src={zoomOut}
											className="zoom-btn"
											alt="zoom-out"
											onClick={() => zoomValue > 100 && setZoom(zoomValue - 10)}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="chat-icon-container">
						<div className="header-separator">&nbsp;</div>

						<div className="chat-container" onClick={toggleChat}>
							<img src={chatIcon} alt="chat-icon" />
							<div className="chat-title"><Translate alias="Board" word="Chat" /></div>
						</div>
					</div>
					<div className="logout-btn-container">
						<Dropdown>
							<Dropdown.Toggle
								id="dropdown-basic"
								className="language-btn"
							>
								{currentLang}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{languages
									.filter((x) => x.id != localStorage.langId)
									.map((lang) => (
										<Dropdown.Item
											key={lang.id}
											onClick={() =>
												onChangeLang(
													lang.id,
													lang.isRTL
												)
											}
										>
											{lang.name}
										</Dropdown.Item>
									))}
							</Dropdown.Menu>
						</Dropdown>
						<div className="logout-btn">
							<img src={logoutIcon} alt="logout" />
						</div>
					</div>
					<div className="partner-logo p-1"></div>
					{ /*partnerLogo && (
						<div className="partner-logo p-1">
							<img
								src={URLs.backendURL + partnerLogo}
								className="App-logo"
								alt="App-logo"
							/>
						</div>
					)*/ }
				</div>
			</div>
			{modalChat && <ChatComponent closeChat={toggleChat} session={session} dispatch={dispatch} />}
		</>
	);
}
