export const initialBoardState = {
  rightBlock: {
    depreciation: [
      {
        name: "Property Depreciation",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Equipment Depreciation",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Other Depreciation",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Overheads Personnel",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Overheads Personnel",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Overheads Personnel",
        hasContainer: false,
        coins: [],
      },
      {
        name: "R&D Costs",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Other Costs",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Other Income",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Marketing",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Indirect Sales Cost",
        hasContainer: false,
        coins: [],
      },
      {
        name: "HR Development",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Severance",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Lease",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Interest",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Tax",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Dividends",
        hasContainer: false,
        coins: [],
      },
    ],
    costOfSales: [
      {
        name: "Cost of Sales",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cost of Sales",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cost of Sales",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cost of Sales",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cost of Sales",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cost of Sales",
        hasContainer: false,
        coins: [],
      },
    ],
    reputation: 0,
    motivation: 0,
  },
  leftBlock: {
    cash: [
      {
        name: "Cash",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cash",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cash",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cash",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cash",
        hasContainer: false,
        coins: [],
      },
      {
        name: "Cash",
        hasContainer: false,
        coins: [],
      },
    ],
    fixedAssets: [
      {
        name: "Property",
        hasContainer: false,
        coins: [],
        rented: false,
      },
      {
        name: "Equipment",
        hasContainer: false,
        coins: [],
      },
    ],
    lowCostHub: {
      pins: [{ checked: false }, { checked: false }],
      containers: [
        {
          name: "Property",
          hasContainer: false,
          coins: [],
          rented: false,
        },
        {
          name: "Equipment",
          hasContainer: false,
          coins: [],
        },
      ],
    },
    centralHub: {
      pins: [{ checked: false }, { checked: false }],
      containers: [
        {
          name: "Property",
          hasContainer: false,
          coins: [],
          rented: false,
        },
        {
          name: "Equipment",
          hasContainer: false,
          coins: [],
        },
      ],
    },
    equity: 0,
    credit5: { value: 0, checked: false },
    credit10: { value: 0, checked: false },
    tradeReceivables: {
      name: "Trade Receivables",
      hasContainer: false,
      coins: [],
    },
    services: [
      {
        id: 1,
        pins: [{ checked: false }],

        hasContainer: false,
        coins: [],
      },
      {
        id: 2,
        pins: [{ checked: false }, { checked: false }],

        hasContainer: false,
        coins: [],
      },
      {
        id: 3,
        pins: [{ checked: false }, { checked: false }, { checked: false }, { checked: false }],

        hasContainer: false,
        coins: [],
      },
    ],
  },

  regions: [
    {
      id: 1,
      pins: [{ checked: false }],
      services: [
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
      ],
      employees: [
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      pins: [{ checked: false }, { checked: false }],
      services: [
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
      ],
      employees: [
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      pins: [{ checked: false }, { checked: false }, { checked: false }],
      services: [
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
        [
          {
            name: "Property",
            hasContainer: false,
            coins: [],
            rented: false,
          },
          {
            name: "Equipment",
            hasContainer: false,
            coins: [],
          },
          {
            name: "Cost of Sales",
            hasContainer: false,
            coins: [],
          },
        ],
      ],
      employees: [
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
        {
          firstRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          secondRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
          thirdRow: [
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
            {
              checked: false,
            },
          ],
        },
      ],
    },
  ],
};
