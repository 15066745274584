import React, { useState } from "react";
import "../../assets/css/FixedBars.css";
import kitsIcon from "../../assets/images/kits-icon.png";
import MainDraggableContainer from "../draggables/MainDraggableContainer";
import MainDraggableCoin from "../draggables/MainDraggableCoin";
import MainDraggableAvatar from "../draggables/MainDraggableAvatars";
import MainDraggablePin from "../draggables/MainDraggablePin";
import MainDraggableToken from "../draggables/MainDraggableToken";
import MainDraggableDelete from "../draggables/MainDroppableDelete";
import MainDraggableSilverAvatar from "../draggables/MainDraggableSilverAvatars";

import Translate from "../../utils/Translate";

const FixedLeftBar = ({ state, canEdit }) => {
  const [isMenuOpened, setMenuOpened] = useState(false);

  const updateUI = (panelType, status) => {
    const appContainer = document.getElementsByClassName("center-container");
    const menuContainer = document.getElementsByClassName("kitContainer");

    if (status === "close") {
      appContainer[0].classList.remove("moveLeft");
      menuContainer[0].classList.remove("fullWidth");
    } else {
      appContainer[0].classList.remove("moveRight");
      appContainer[0].classList.add("moveLeft");

      if (panelType === "MENU") {
        menuContainer[0].classList.add("fullWidth");
      } else {
        setMenuOpened(false);
      }
    }
  };

  const toggleMenu = () => {
    if (isMenuOpened) {
      setMenuOpened(false);
      updateUI("MENU", "close");
    } else {
      setMenuOpened(true);
      updateUI("MENU", "open");
    }
  };

  return (
    <>
      <div className="kits-objects-btn" onClick={toggleMenu}>
        <div className="button">
          <img alt="kits-icon" src={kitsIcon} />
          <div className="text"><Translate alias="TeamTable" word="Team Table" /></div>
        </div>
      </div>
      <div className="kitContainer">
        <div className="blur">
          <div className="object-container-div">
            <div className="container-text"><Translate alias="TeamTable" word="Containers" /></div>
            <MainDraggableContainer canEdit={canEdit} />
          </div>
          <div className="kits-separator" />
          <div className="object-container-div">
            <div className="container-text">
              <Translate alias="TeamTable" word="Cash" />
              <div className="object-value">
                <span className="notranslate">{state.coins}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <MainDraggableCoin value={10} canEdit={canEdit} />
              </div>
              <div>
                <MainDraggableCoin value={1} canEdit={canEdit} />
              </div>
              <div>
                <MainDraggableCoin value={0.5} canEdit={canEdit} />
              </div>
            </div>
          </div>
          <div className="kits-separator" />
          <div className="object-container-div">
            <div className="container-text"><Translate alias="TeamTable" word="Employees" /></div>
            <div className="d-flex flex-wrap justify-content-around p-2">
              <div>
                <MainDraggableSilverAvatar count={state.silverAvatar} canEdit={canEdit} />
                <div className="object-value-center">
                  <span className="notranslate">{state.silverAvatar}</span>
                </div>
              </div>
              <div>
                <MainDraggableAvatar count={state.goldAvatar} serviceIndex={0} canEdit={canEdit} />
                <div className="object-value-center">
                  <span className="notranslate">{state.goldAvatar}</span>
                </div>
              </div>

              <div>
                <MainDraggableAvatar count={state.greenAvatar} serviceIndex={1} canEdit={canEdit} />
                <div className="object-value-center">
                  <span className="notranslate">{state.greenAvatar}</span>
                </div>
              </div>
              <div>
                <MainDraggableAvatar count={state.blueAvatar} serviceIndex={2} canEdit={canEdit} />
                <div className="object-value-center">
                  <span className="notranslate">{state.blueAvatar}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="kits-separator" />
          <div className="object-container-div">
            <div className="container-text">
              <Translate alias="TeamTable" word="Pins" />
              <div className="object-value">
                <span className="notranslate">{state.pins}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <MainDraggablePin canEdit={canEdit} />
              </div>
            </div>
          </div>

          <div className="kits-separator" />
          {state.token5 > 0 || state.token10 > 0 ? (
            <div className="object-container-div">
              <div className="container-text"><Translate alias="TeamTable" word="Credit" /></div>
              <div className="d-flex justify-content-between p-2">
                {state.token5 > 0 && (
                  <div>
                    <MainDraggableToken tokenType={5} value={state.token5} canEdit={canEdit} />
                  </div>
                )}

                {state.token10 > 0 && (
                  <div>
                    <MainDraggableToken tokenType={10} value={state.token10} canEdit={canEdit} />
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="kits-separator" />
          <div className="object-container-div">
            <div className="container-text"><Translate alias="TeamTable" word="Delete" /></div>
            <MainDraggableDelete canEdit={canEdit} />
            <div className="clearfix"></div>
          </div>
          <div className="kits-separator"></div>
          <div style={{ height: "30px" }}></div>
        </div>
      </div>
    </>
  );
};

export default FixedLeftBar;
