import React, { useState } from "react";
// Css
import "../assets/css/right-block.css";
import ContainerField from "./draggables/ContainerField";
// images
import { ReactComponent as REPUTATION } from "../assets/svg/REPUTATION.svg";
import { ReactComponent as Happy } from "../assets/svg/happy.svg";
import happyFace from "../assets/images/HappyFace.png";
import sadFace from "../assets/images/SadFace.png";
import { Collapse, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Translate from "../utils/Translate";

export default function RightBlock({ canEdit, state, dispatch, coinsCount }) {
  const [isOpenFirst, setIsOpenFirst] = useState(true);
  const [isOpenSecond, setIsOpenSecond] = useState(true);

  const toggleFirst = () => setIsOpenFirst(!isOpenFirst);
  const toggleSecond = () => setIsOpenSecond(!isOpenSecond);
  return (
    <div className="right-block">
      <div className="flex-row" style={{ marginTop: "50px" }}>
        <div className="block-container">
          <img
            src={state.reputation >= 0 ? happyFace : sadFace}
            alt="happyFace"
            className="emoji"
          />
          <div
            className="d-flex justify-space-around w-100"
            style={{ height: "40px", fontSize: "30px" }}
          >
            <span>{state.reputation}</span>
            <div className="svg-container">
              <REPUTATION />
            </div>
          </div>
          <span style={{ textTransform: "uppercase" }}><Translate alias="Board" word="REPUTATION" /></span>
        </div>
        <div className="block-container">
          <img
            src={state.motivation >= 0 ? happyFace : sadFace}
            alt="happyFace"
            className="emoji"
          />
          <div
            className="d-flex justify-space-around w-100 motivation"
            style={{ height: "40px", fontSize: "30px" }}
          >
            <span>{state.motivation}</span>
            <div className="svg-container">
              <Happy />
            </div>
          </div>
          <span style={{ textTransform: "uppercase" }}><Translate alias="Board" word="MOTIVATION" /></span>
        </div>
      </div>
      <Button className="button-header" onClick={toggleFirst}>
        <FontAwesomeIcon icon={isOpenFirst ? faChevronDown : faChevronRight} />
      </Button>
      <Collapse isOpen={isOpenFirst}>
        <div className="grid-container">
          {state.costOfSales.map((containerItem, index) => (
            <ContainerField
              coinsCount={coinsCount}
              containerItem={containerItem}
              index={index}
              key={index}
              branch={"rightBlock"}
              dispatch={dispatch}
              alias="costOfSales"
              customClass="money-svg"
              canEdit={canEdit}
            />
          ))}
        </div>
      </Collapse>

      <Button
        className="button-header"
        onClick={toggleSecond}
        style={{ borderTop: "1px solid #203b52" }}
      >
        <FontAwesomeIcon icon={isOpenSecond ? faChevronDown : faChevronRight} />
      </Button>
      <Collapse isOpen={isOpenSecond}>
        <div className="grid-container">
          {state.depreciation.map((containerItem, index) => (
            <ContainerField
              coinsCount={coinsCount}
              containerItem={containerItem}
              key={index}
              index={index}
              alias={"depreciation"}
              branch={"rightBlock"}
              canEdit={canEdit}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}
