import React from "react";

import { Container, Row, Col, Table, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { FormGroup, Label, Input } from "reactstrap";
import RealtimeConnection from "../../utils/RealtimeConnection";
import InputNumber from "rc-input-number";

import Translate from "../../utils/Translate";

import "../../assets/css/Forms.css";
const subTextsFirst = {
  "($5 / $5)": "(Y1 : $5)",
  "($5 / $5 / $5 / $5)": "(Y1 : $5 / Y2 : $5)",
  "($2 / $2)": "(Y1 : $4)",
  "($2 / $2 / $2)": "(Y1 : $4 / Y2 : $2)",
};
const subTextSecond = {
  0: { P: "P ($10)", E: "E ($5)" },
  1: { P: "P ($5)", E: "E ($5)" },
  2: { P: "P ($2)", E: "E ($5)" },
};

export default function StrategyPaper({
  canEdit,
  dispatch,
  strategyPaper,
  session,
  isSessionLong,
}) {
  function closeStrategy(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch({ type: "strategyPaper", value: false });
  }

  function handleChange(
    alias,
    companyIndex,
    value,
    categoryIndex,
    subCategoryIndex,
    fieldIndex,
    liquidityIndex
  ) {
    RealtimeConnection.changeStrategyPaper({
      year: session.formSelectedYear,
      alias,
      companyIndex,
      value,
      categoryIndex,
      subCategoryIndex,
      fieldIndex,
      liquidityIndex,
    });
  }
  function changeFormYear(value) {
    RealtimeConnection.changeFormYear({
      year: value,
      type: "strategyPaper",
    });
  }
  let yearsOptions = [];
  for (let i = 0; i <= session.year; i++) {
    yearsOptions.push(
      <Dropdown.Item key={i} eventKey={i} onClick={() => changeFormYear(i)}>
        Year {i}
      </Dropdown.Item>
    );
  }

  let formStatus = canEdit ? true : false;
  if (session.formSelectedYear !== session.year) {
    formStatus = false;
  }

  return (
    <Container className="formContainer">
      <Row>
        <Col>
          <div className="formHeader">
            <div className="headerTitle bold">
              <Translate alias="Forms" word="Strategy Paper" />
            </div>
            <DropdownButton
              as={ButtonGroup}
              variant="outline-secondary"
              title={`Year ${session.formSelectedYear}`}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              {yearsOptions}
            </DropdownButton>
            <div className="closeForm" onClick={closeStrategy}>
              X
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table bordered>
            <tbody>
              {strategyPaper.first.map((company, companyIndex) => (
                <tr key={companyIndex}>
                  <td style={{ width: "10px" }}>
                    <div className="bold">
                      <Translate alias="Forms" word={company.name} />
                    </div>
                  </td>
                  <td>
                    {company.categories.map((category, categoryIndex) => {
                      const subText = isSessionLong
                        ? category.subText
                        : subTextsFirst[category.subText];
                      return (
                        <tr key={categoryIndex}>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  disabled={!canEdit}
                                  checked={category.value}
                                  onChange={(e) =>
                                    handleChange(
                                      "first",
                                      companyIndex,
                                      e.target.checked,
                                      categoryIndex
                                    )
                                  }
                                />{" "}
                                <div className="bold">
                                  <Translate alias="Forms" word={category.name.trim()} />
                                </div>
                                <div className="subtext">
                                  {subText}
                                </div>
                              </Label>
                            </FormGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </td>
                  <td>
                    <InputNumber
                      min="0"
                      className="liquidity largeField"
                      value={company.Liquidity}
                      onChange={(value) => handleChange("first", companyIndex, value)}
                      disabled={!canEdit}
                    />
                  </td>
                </tr>
              ))}
              {strategyPaper.second.map((company, companyIndex) => (
                <tr key={companyIndex}>
                  <td>
                    <div className="bold">
                      {
                        isSessionLong ? 
                          company.name
                          : 
                          <Translate alias="Forms" word="Purchase/Lease- Property (P) Purchase- Equipment (E) Service (S) *Rent only for Service Hub Property" />
                      }
                    </div>
                  </td>
                  <td>
                    {company.categories?.map((category, categoryIndex) => (
                      <tr key={categoryIndex}>
                        <td>
                          <div className="bold border-bottom">
                            <Translate alias="Forms" word={category.name.trim()} />
                          </div>
                          <div className="d-flex justify-content-between">
                            {category.subCategories.map((subCategory, subCategoryIndex) => (
                              <td key={subCategoryIndex}>
                                <div className="bold border-bottom text-center">
                                  <Translate alias="Forms" word={subCategory.name} />
                                </div>
                                {subCategory.fields.map((field, fieldIndex) => {
                                  const name = isSessionLong
                                    ? field.name
                                    : categoryIndex === 3
                                    ? subCategory.name === "Low Cost"
                                      ? subTextSecond[2][field.name]
                                      : subTextSecond[1][field.name]
                                    : subTextSecond[0][field.name];
                                  return (
                                    <FormGroup check key={fieldIndex}>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          disabled={!canEdit}
                                          checked={field.value}
                                          onChange={(e) =>
                                            handleChange(
                                              "second",
                                              companyIndex,
                                              e.target.checked,
                                              categoryIndex,
                                              subCategoryIndex,
                                              fieldIndex
                                            )
                                          }
                                        />{" "}
                                        <div className="bold">
                                          {name}
                                        </div>
                                      </Label>
                                    </FormGroup>
                                  );
                                })}
                              </td>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td>
                    <InputNumber
                      min="0"
                      className="liquidity largeField"
                      value={company.Liquidity}
                      onChange={(value) => handleChange("second", companyIndex, value)}
                      disabled={!canEdit}
                    />
                  </td>
                </tr>
              ))}
              {strategyPaper.third.map((company, companyIndex) => (
                <tr key={companyIndex}>
                  <td>
                    <div className="bold">
                      <Translate alias="Forms" word={company.name} />
                    </div>
                  </td>
                  <td>
                    {company.categories.map((category, categoryIndex) => (
                      <tr key={categoryIndex}>
                        <td>
                          <div className="bold border-bottom">
                            <Translate alias="Forms" word={category.name.trim()} />
                          </div>
                          <div className="d-flex justify-content-between align-items-end">
                            {category.subCategories.map((subCategory, subCategoryIndex) => (
                              <td key={subCategoryIndex} style={{ position: "relative" }}>
                                <div className="bold border-bottom text-center">
                                  {subCategory.name}
                                </div>
                                {subCategoryIndex === 0 && (
                                  <div className="plus-minus">
                                    <span>+ / -</span>
                                    <span><Translate alias="Forms" word="New Total" /></span>{" "}
                                  </div>
                                )}
                                {subCategory.fields.map((field, fieldIndex) => (
                                  <>
                                    <InputNumber
                                      key={fieldIndex}
                                      min="0"
                                      className="liquidity"
                                      value={field.value}
                                      onChange={(value) =>
                                        handleChange(
                                          "third",
                                          companyIndex,
                                          value,
                                          categoryIndex,
                                          subCategoryIndex,
                                          fieldIndex
                                        )
                                      }
                                      disabled={!canEdit}
                                    />
                                    <div className="bold border-bottom text-center">
                                      {field.name}
                                    </div>
                                  </>
                                ))}
                              </td>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td className="td-liquidity">
                    <div className="d-flex flex-column">
                      {company.Liquidity.map((item, liquidityIndex) => (
                        <>
                          <InputNumber
                            min="0"
                            className="liquidity largeField"
                            value={item.value}
                            onChange={(value) =>
                              handleChange(
                                "third",
                                companyIndex,
                                value,
                                undefined,
                                undefined,
                                undefined,
                                liquidityIndex
                              )
                            }
                            disabled={!canEdit}
                          />

                          <div
                            className="bold text-center"
                            style={
                              company.Liquidity.length - 1 !== liquidityIndex
                                ? { borderBottom: "1px solid white" }
                                : null
                            }
                          >
                            <Translate alias="Forms" word={item.name} />
                          </div>
                        </>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}

              <tr key={4}>
                <td>
                  <div className="bold">
                    <Translate alias="Forms" word={strategyPaper.fourth.name} />
                  </div>
                </td>
                <td style={{ verticalAlign: "bottom" }}>
                  <div className="d-flex">
                    {strategyPaper.fourth.fields.map((field, fieldIndex) => (
                      <>
                        <InputNumber
                          key={fieldIndex}
                          min="0"
                          className="liquidity"
                          value={field.value}
                          onChange={(value) =>
                            handleChange(
                              "fourth",
                              undefined,
                              value,
                              undefined,
                              undefined,
                              fieldIndex,
                              undefined
                            )
                          }
                          disabled={!canEdit}
                        />
                        <div className="bold text-center">
                          <Translate alias="Forms" word={field.name} />
                        </div>
                      </>
                    ))}
                  </div>
                </td>

                <td className="td-liquidity">
                  <div className="d-flex flex-column">
                    {strategyPaper.fourth.Liquidity.map((item, liquidityIndex) => (
                      <>
                        <InputNumber
                          min="0"
                          className="liquidity largeField"
                          value={item.value}
                          onChange={(value) =>
                            handleChange(
                              "fourth",
                              undefined,
                              value,
                              undefined,
                              undefined,
                              undefined,
                              liquidityIndex
                            )
                          }
                          disabled={!canEdit}
                        />

                        <div
                          className="bold text-center"
                          style={
                            strategyPaper.fourth.Liquidity.length - 1 !== liquidityIndex
                              ? { borderBottom: "1px solid white" }
                              : null
                          }
                        >
                          <Translate alias="Forms" word={item.name} />
                        </div>
                      </>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
