import React from "react";
import AvatarContainer from "../draggables/AvatarContainer";
import SilverAvatarContainer from "../draggables/SilverAvatarContainer";

export default function Employee({ canEdit, employee, regionIndex, serviceIndex }) {
  return (
    <div>
      <div className="employee-block">
        <div>
          {employee.firstRow.map((x, y) => (
            <SilverAvatarContainer
              key={y + 1}
              index={y}
              checked={x.checked}
              serviceIndex={serviceIndex}
              alias="firstRow"
              regionIndex={regionIndex}
              canEdit={canEdit}
            />
          ))}
        </div>
        <div>
          {employee.secondRow.map((x, y) => (
            <AvatarContainer
              key={y + 3}
              index={y}
              checked={x.checked}
              serviceIndex={serviceIndex}
              alias="secondRow"
              regionIndex={regionIndex}
              canEdit={canEdit}
            />
          ))}
        </div>
        <div>
          {employee.thirdRow.map((x, y) => (
            <AvatarContainer
              key={y + 7}
              index={y}
              checked={x.checked}
              serviceIndex={serviceIndex}
              alias="thirdRow"
              regionIndex={regionIndex}
              canEdit={canEdit}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
